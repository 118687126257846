import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';

class Home extends ChemComponent {	
	constructor(props) {
		super(props);
		
		this.state = {
			delivery_original: {
				PERSON_ID: null,
				CAL_UID: '',
				LNAME: '',
				PREFERRED_NAME: '',
				FNAME: '',
				DEPT_ID: null,
				CONTACT_ID: null,
				ROLE_ID: null,
				ROOM_ID: null,
				OUTSIDE_LOCATION: '',
				OFFICE_PHONE: '',
				EMAIL: '',
				ROOM_NAME: '',
				BUIDLING_ID: null,
				LAB_BUILDING: null,
				LAB_LOCATION: '',
				LAB_BUILDING_NAME: '',
				LOCATION: '',
				PHONE2: ''				
			},
			delivery: {
				PERSON_ID: null,
				CAL_UID: '',
				LNAME: '',
				PREFERRED_NAME: '',
				FNAME: '',
				DEPT_ID: null,
				CONTACT_ID: null,
				ROLE_ID: null,
				ROOM_ID: null,
				OUTSIDE_LOCATION: '',
				OFFICE_PHONE: '',
				EMAIL: '',
				ROOM_NAME: '',
				BUIDLING_ID: null,
				LAB_BUILDING: null,
				LAB_LOCATION: '',
				LAB_BUILDING_NAME: '',
				LOCATION: '',
				PHONE2: '',
				CONFIRM: false
			},
			renderKey: 0
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Reuse/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'DELIVERY_LOCATION_VW',
				search: { Attribute: 'CAL_UID', Operator: '=', StringValue: this.props.user.uid },
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length > 0) {
					var delivery = data.Data[0];
					// turn nulls into empty strings
					if (self.isEmpty(delivery.EMAIL)) delivery.EMAIL = '';
					if (self.isEmpty(delivery.PHONE2)) delivery.PHONE2 = '';
					if (self.isEmpty(delivery.LAB_LOCATION)) delivery.LAB_LOCATION = '';
					
					// make copy of object to compare original values
					var delivery_original = self.copyObject(delivery);
					
					// add confirmation checkbox to delivery
					delivery.CONFIRM = false;
						
					self.mergeState({
						delivery: delivery,
						delivery_original: delivery_original,
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Course Error', data.Data.length + ' Delivery locations found for Cal UID ' + self.props.user.uid);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	onChange(accessor, value) {
		var delivery = this.copyObject(this.state.delivery);
		if (accessor === 'LAB_BUILDING' && this.isNumeric(value)) {
			this.setByAccessor(delivery, accessor, +value);
		} else {
			this.setByAccessor(delivery, accessor, value);
		}
		// any change resets the confirmation checkbox
		if (accessor !== 'CONFIRM') {
			this.setByAccessor(delivery, 'CONFIRM', false);
		}
		this.mergeState({
			delivery: delivery,
			renderKey: this.state.renderKey + 1
		});
	}

	deliveryHasChanged() {
		return (this.state.delivery.EMAIL !== this.state.delivery_original.EMAIL ||
			this.state.delivery.PHONE2 !== this.state.delivery_original.PHONE2 ||
			this.state.delivery.LAB_LOCATION !== this.state.delivery_original.LAB_LOCATION ||
			this.state.delivery.LAB_BUILDING !== this.state.delivery_original.LAB_BUILDING);
	}
	
	onClickContinue() {
		var validation_errors = this.validate(deliveryColumns, this.state.delivery);
		
		if (!this.state.delivery.CONFIRM) {
			if (this.isEmpty(validation_errors)) {
				validation_errors = 'Please check the checkbox confirming accuracy of information.';
			} else {
				validation_errors += ', Confirm';
			}
		}
		
		if (!this.isEmpty(validation_errors)) {
			this.showAlert('Validation Error', validation_errors);
		} else {
			if (this.deliveryHasChanged()) {
				var self = this;
				
				this.ajax({
					type: 'POST',
					url: this.getConfig().host + '/Stores/UpdateDeliveryLocation',
					data: { __RequestVerificationToken: this.props.user.antiForgeryToken, contact: this.state.delivery }
				}).done(function (data) {
					if (data.Success) {
						this.redirectToSearch();
					} else {
						self.showAlert('Server Error', data.Message);
					}
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});			
			} else {
				this.redirectToSearch();
			}
		}
	}
	
	redirectToSearch() {
		this.props.confirmLocation(true);
		this.props.navigate('/Reuse/Search');
	}

	render() {
		return(
			<Container fluid>
			  <Row className='g-0'>
			    <Col md={12} lg={11} xl={10}>
						<table align="center" width="100%">
							<tr bgcolor="#FFFF33">
								<td colspan="2" style={{ border: '1px solid #000', padding: '12px' }}>
									<h2 align="center"><strong>DONATE CHEMICALS TO REUSE!!!</strong></h2>
										<div align="center">
											<font size="3">
												The Chemical ReUse Facility relies on donations from labs like yours to keep its stock up. <br /> 
												Support our Green Chemistry ethic and campus sustainability efforts <br />
												by donating your excess chemicals!<br />
											</font>
											<font size="3">
												<span style={{ color: 'red' }}>We accept Lecture Bottle donations!</span>
											</font>
											<br />
											<table>
												<tr>
													<td style={{ paddingBottom: '10px' }}><font size="+6">&#8594;</font></td>
													<td><Button type="submit" name="donate_button">DONATE</Button></td>
													<td style={{ paddingBottom: '10px' }}><font size="+6">&#8592;</font></td>
												</tr>
											</table>
										</div>
										<font size="3">Acceptance Criteria:<br /></font>
										<ul>
											<li><font size="3">Quality-In, Quality-Out (donate chemicals that are in good shape!)</font></li>
											<li><font size="3">In original product containers only (exception:  elemental metal pieces)</font></li>
											<li><font size="3">No cracked bottles, broken bottle lids, defaced labels, etc.</font></li>
											<li><font size="3">No expired or contaminated chemicals (free of glass like pipettes tips as well)</font></li> 
											<li><font size="3">No water-logged hygroscopics</font></li>
											<li><font size="3">Lecture Bottle labels intact; tare & net weights notated; no corrosion present on valves</font></li> 
										</ul>
								</td>
							</tr>
							<tr>
								<td bgcolor="#CCCCCC" colspan="2" style={{ border: '1px solid #000', padding: '12px' }}>
									<h5><em>In order to have chemicals from the Chemical ReUse Facility delivered to the correct laboratory location</em>:</h5> 
									<ul><font size="3">
										<li>Please review the directory information displayed</li>
										<li>Pay special attention to the Lab Location field</li> 
										<li>Make any corrections, if needed</li>
										<li>Submit your changes <u>OR</u><br />
										If you already have a valid lab location and your information is correct: check the box at the bottom and click Continue to proceed</li>
									</font></ul>
								</td>
							</tr>
							<tr bgcolor="#999999">
								<td colspan="2" style={{ border: '1px solid #000', padding: '12px' }}><h5>College Record for {this.props.user.name}</h5></td>
							</tr>
						</table>
				</Col>
			  </Row>
			  <Row className='g-0'>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
					<ChemEdit parent={this} columns={deliveryColumns} data={this.state.delivery} user={this.props.user} renderKey={this.state.renderKey} 
					   onChange={(accessor, value) => this.onChange(accessor, value)}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 5 }}>
					    <Col>
						  <Button id="continue" variant="warning" type="button" className="float-end"
						    onClick={(event) => this.onClickContinue(event)}>{this.deliveryHasChanged() ? "Update and Continue" : "Continue"}</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				</Col>
			  </Row>
			</Container>
		);
	}
}

const deliveryColumns = [
	{ Header: 'Email Address', accessor: 'EMAIL', row: 0, col: 0, required: true },
	{ Header: 'Lab Phone', accessor: 'PHONE2', row: 0, col: 1 },
	{ Header: 'Lab Location', accessor: 'LAB_LOCATION', subscript: '(room)', row: 1, col: 0, required: true },
	{ Header: 'Lab Location', accessor: 'LAB_BUILDING', labelAccessor: 'LAB_BUILDING_NAME', type: 'select', subscript: '(building)', row: 1, col: 1, required: true,
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'COLLEGE.BUILDING', order: 'BUILDING_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Confirm', accessor: 'CONFIRM', type: 'checkbox', label: 'This information is correct and chemicals can be delivered here',
		row: 2, col: 0, required: true }
];

export default withRouter(Home);