import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class ChemComponent extends React.Component {

	ajax(ajaxArgs) {
		return new AjaxWrapper(this, ajaxArgs).ajax();			
	}

	cancelModalDialog(callback) {
		this.props.parent.cancelModalDialog(callback);
	}

	convertWidthsToExcel(cols) {
		var newCols = this.copyColumns(cols);
		for (var i = 0; i < newCols.length; i++) {
			if (newCols[i].width) newCols[i].width /= 5;
		}
		return newCols;
	}

	combineChangeInfo(created, modified) {
		if (created) {
			if (modified) {
				return created + ' / ' + modified;
			} else {
				return created;
			}
		} else {
			if (modified) {
				return modified;
			} else {
				return '';
			}
		}
	}

	composeChangeInfo(name, date) {
		if (!name) {
			if (!date) {
				return null;
			} else {
				return this.dateToString(this.getDate(date));
			}
		} else {
			if (!date) {
				return name;
			} else {
				return name + ' ' + this.dateToString(this.getDate(date));
			}
		}
	}
	
	copyArrays(arr1, arr2) {
		var arr1Copy = this.copyObject(arr1);
		var arr2Copy = this.copyObject(arr2);
		return arr1Copy.concat(arr2Copy);
	}
	
	copyColumns(cols) {
		var colsCopy = this.copyObject(cols);
		for (var i = 0; i < cols.length; i++) {
			if (cols[i].Header) {
				colsCopy[i].Header = cols[i].Header;
			}
			if (cols[i].Cell) {
				colsCopy[i].Cell = cols[i].Cell;
			}
			if (cols[i].Editor) {
				colsCopy[i].Editor = cols[i].Editor;
			}
		}
		return colsCopy;
	}

	copyObject(obj) {
		return JSON.parse(JSON.stringify(obj));
	}
	
	copyTable(tbl) {
		var tblCopy = this.copyObject(tbl);
		for (var i = 0; i < tbl.columns.length; i++) {
			if (tbl.columns[i].Header) {
				tblCopy.columns[i].Header = tbl.columns[i].Header;
			}
			if (tbl.columns[i].Cell) {
				tblCopy.columns[i].Cell = tbl.columns[i].Cell;
			}
			if (tbl.columns[i].Editor) {
				tblCopy.columns[i].Editor = tbl.columns[i].Editor;
			}
		}
		return tblCopy;
	}
	
	copyTreeMenuItems(tmi) {
		var tmiCopy = [];
		
		for (var i = 0; i < tmi.length; i++) {
			if (tmi[i].nodes) {
				tmiCopy.push(this.mergeObject(tmi[i], { nodes: this.copyTreeMenuItems(tmi[i].nodes) }));
			} else {
				var nodeCopy = this.copyObject(tmi[i]);
				if (tmi[i].onClick) nodeCopy.onClick = tmi[i].onClick;
				tmiCopy.push(nodeCopy);
			}
		}
		
		return tmiCopy;
	}
	
	createHiddenFormData(obj) {
		var self = this;
		var nvpairs = [];
		
		Object.keys(obj).forEach(function(key) {
			self.createHiddenFormField(nvpairs, key, obj[key]);
		});
		
		return nvpairs;
	}
	
	createHiddenFormField(nvpairs, name, value) {
		if (Array.isArray(value)) {
			for (var i = 0; i < value.length; i++) {
				this.createHiddenFormField(nvpairs, name + '[' + i + ']', value[i]);
			}
		} else if (this.isObject(value)) {
			var self = this;
			Object.keys(value).forEach(function(key) {
				self.createHiddenFormField(nvpairs, name + '[' + key + ']', value[key]);
			});
		} else {
			// can't put null/undefined as a value because
			// react will get confused and mix it up with other fields
			if (value === undefined || value === null) {
				nvpairs.push({ name: name, value: '' });
			} else {
				nvpairs.push({ name: name, value: value });
			}
		}
	}
	
	dateTimeToMVC(date) {
		if (date === undefined || date === null) return date;
		date = new Date(date);
		var sec = date.getSeconds();
		var min = date.getMinutes();
		var h = date.getHours();
		var d = date.getDate();
		var m = date.getMonth() + 1; //Month from 0 to 11
		var y = date.getFullYear();
		return (m <= 9 ? ('0' + m) : m) + '/' + 
			   (d <= 9 ? ('0' + d) : d) + '/' + 
			   y.toString() + ' ' +
			   (h <= 9 ? ('0' + h) : h) + ':' +
			   (min <= 9 ? ('0' + min) : min) + ':' +
			   (sec <= 9 ? ('0' + sec) : sec);
	}		
	
	dateTimeToString(dateArg) {
		var date = dateArg;
		if (!this.isDate(date)) date = this.getDate(date);
		if (!this.isDate(date)) return date;
		var s = date.getSeconds();
		var min = date.getMinutes();
		var h = date.getHours();
		var d = date.getDate();
		var m = date.getMonth() + 1; //Month from 0 to 11
		var y = date.getFullYear();
		return (m <= 9 ? ('0' + m) : m) + '/' + 
			   (d <= 9 ? ('0' + d) : d) + '/' + 
			   y.toString().substring(2) + ' ' +
			   (h <= 9 ? ('0' + h) : h) + ':' +
			   (min <= 9 ? ('0' + min) : min) + ':' +
			   (s <= 9 ? ('0' + s) : s);
	}		
	
	dateToString(dateArg) {
		var date = dateArg;
		if (!this.isDate(date)) date = this.getDate(date);
		if (!this.isDate(date)) return date;
		var d = date.getDate();
		var m = date.getMonth() + 1; //Month from 0 to 11
		var y = date.getFullYear();
		return (m <= 9 ? ('0' + m) : m) + '/' + (d <= 9 ? ('0' + d) : d) + '/' + y.toString().substring(2);
	}
	
	equalsIgnoreCase(str1, str2) {
		return ('' + str1).toUpperCase() === ('' + str2).toUpperCase() &&
			('' + str1).toLowerCase() === ('' + str2).toLowerCase();
	}
	
	findColumnByAccessor(columns, accessor) {
		for (var i = 0; i < columns.length; i++) {
			if (columns[i].accessor === accessor) return i;
		}
		return -1;
	}

	formatCurrency(n) {
		if (this.isNumeric(n)) {
			try {
				return (+n).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
			} catch (e) {
				return n;
			}
		} else {
			return '';
		}
	}
	
	getAntiForgeryToken() {
		if (this.props.parent) {
			return this.props.parent.getAntiForgeryToken();
		} else {
			return this.state.antiForgeryToken;
		}
	}
	
	getAuthServer() {
		if (this.isProduction()) {
			return 'auth.berkeley.edu';
		} else {
			return 'auth-test.berkeley.edu';
		}
	}
	
	getBoolean(val, def) {
		if (this.isEmpty(val)) {
			if (def) {
				return true;
			} else {
				return false;
			}
		} else {
			if (val) {
				return true;
			} else {
				return false;
			}
		}
	}
	
	getByAccessor(data, accessor) {		
		// split accessor into components
		var components = accessor.split('.');
		
		var value = data;
		
		// for each component
		for (var i = 0; i < components.length; i++) {
			// look for valid (greatly simplified) identifier
			var idmatch = components[i].match(/^([a-zA-Z_$][a-zA-Z0-9_]*)(?:\[((?:[1-9][0-9]*)|0)\])?$/);
			
			// if this is a valid identifier
			if (idmatch) {
				// get value with identifier
				value = value[idmatch[1]];
				
				// if undefined or null, return null
				if (value === undefined || value === null) return null;
				
				// if there is an array index
				if (idmatch[2]) {					
					// get array index
					var idx = +idmatch[2];
					
					// if array is too short, return null
					if (idx >= value.length) return null;
					
					// get value at index
					value = value[idx];

					// if value is undefined or null, return null
					if (value === undefined || value === null) return null;
				}
			} else {
				// invalid identifier
				return null;
			}
		}
		
		return value;
	}
	
	getByAccessorForInput(data, accessor) {
		var value = this.getByAccessor(data, accessor);
		return (value === undefined || value === null) ? '' : value;
	}
	
	getCellFragment(text) {
		if (!text) return null;
		var start = 0;
		var lines = [];
		var crlfIdx;
		
		while ((crlfIdx = text.indexOf('\r\n', start)) >= 0) {
			lines.push(text.substring(start, crlfIdx));
			start = crlfIdx + 2;
		}
		
		return (<>
			{lines.map((line, index) => {
				return (<React.Fragment key={index}>{line}<br /></React.Fragment>);
			})}
			{text.substring(start)}
		</>);
	}

	getConfig() {
		if (this.props.parent) {
			return this.props.parent.getConfig();
		} else {
			return this.props.config;
		}
	}
			
	getCookie(name) {
		var cookieValue = null;
		if (document.cookie && document.cookie !== '') {
			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				var cookie = $.trim(cookies[i]);
				if (cookie.substring(0, name.length + 1) === (name + '=')) {
					cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
					break;
				}
			}
		}
		return cookieValue;
	}
	
	getDate(mvcDate) {
		if (/^\/Date\((-?\d+)\)\/$/.test(mvcDate)) {
			return new Date(parseInt(mvcDate.replace(/^\/Date\((-?\d+)\)\/$/gi, '$1')));
		} else if (/^[0-1]?[0-9]\/[0-3]?[0-9]\/([0-9]{2})?[0-9]{2}(\s[0-2][0-9]:[0-5][0-9]:[0-5][0-9])?$/.test(mvcDate)) {
			var match = mvcDate.match(/^([0-1]?[0-9])\/([0-3]?[0-9])\/((?:[0-9]{2})?[0-9]{2})(?:\s([0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?$/);
			var month = match[1];
			while (('' + month).length < 2) month = '0' + month;
			var day = match[2];
			while (('' + day).length < 2) day = '0' + day;
			var year = +match[3];
			if (year < 100) {
				if (year < 50) year += 2000;
				else year += 1900;
			}
			var hour = match[4] || '00';
			var minute = match[5] || '00';
			var second = match[6] || '00';
			return new Date('' + month + '/' + day + '/' + year + ' ' + hour + ':' + minute + ':' + second);
		} else if (/^([0-3][0-9])-([A-Z][A-Z][A-Z])-([0-9][0-9])$/.test(mvcDate)) {
			return new Date(mvcDate.replace(/^([0-3][0-9])-([A-Z][A-Z][A-Z])-([0-9][0-9])$/gi, '$2/$1/$3')
				.replace('JAN', '1')
				.replace('FEB', '2')
				.replace('MAR', '3')
				.replace('APR', '4')
				.replace('MAY', '5')
				.replace('JUN', '6')
				.replace('JUL', '7')
				.replace('AUG', '8')
				.replace('SEP', '9')
				.replace('OCT', '10')
				.replace('NOV', '11')
				.replace('DEC', '12'));
		} else if (mvcDate === 'none' || mvcDate === 'NaN/NaN/NaN NaN:NaN:NaN') {
			return null;
		} else {
			return mvcDate;
		}
	}
	
	getFragment(jsx)
	{
		var start = 0;
		var linkStartIdx;
		var links = [];
		
		// find next link tag
		while ((linkStartIdx = jsx.indexOf('<Link', start)) >= 0) {
			// in case we don't find a complete tag
			var nextStart = start + 5;
			
			// look for complete tag
			var toStartIdx = jsx.indexOf("to='", linkStartIdx + 5);
			if (toStartIdx >= 0) {
				toStartIdx += 4;
				var toEndIdx = jsx.indexOf("'", toStartIdx);
				if (toEndIdx >= 0) {
					var innerTextStartIdx = jsx.indexOf('>', toEndIdx + 1);
					if (innerTextStartIdx >= 0) {
						innerTextStartIdx++;
						var innerTextEndIdx = jsx.indexOf('</Link>', innerTextStartIdx);
						if (innerTextEndIdx >= 0) {
							// complete tag found, advance start past entire tag
							links.push({
								previousText: jsx.substring(start, linkStartIdx),
								to: jsx.substring(toStartIdx, toEndIdx),
								innerText: jsx.substring(innerTextStartIdx, innerTextEndIdx)
							});
							nextStart = innerTextEndIdx + 7;
						}
					}
				}
			}
			
			start = nextStart;
		}
		
		return (<>
			{links.map((link) => {
				return (<>
					{ link.previousText }
					<Link to={link.to}>{link.innerText}</Link>
				</>);
			})}
			{jsx.substring(start)}
		</>);
	}

	getModifiedAccessors(obj1, obj2, prefix)
	{
		var modified = [];
		
		// get list of keys for each object
		var keys1 = Object.keys(obj1);
		var keys2 = Object.keys(obj2);
		
		// for each key in obj1
		for (var i = 0; i < keys1.length; i++) {
			// create the accessor for this key's value
			var accessor = (prefix ? prefix : '') + (prefix && !this.isIndex(keys1[i]) ? '.' : '') +
				(this.isIndex(keys1[i]) ? ('[' + keys1[i] + ']') : keys1[i]);
			
			// if this key isn't in obj2, then it was modified (added/removed)
			if (!keys2.includes(keys1[i])) {
				// for objects, push prefix.key; for arrays, prefix[key]
				modified.push(accessor);
			} else {
				// remove key from keys2
				keys2.splice(keys2.indexOf(keys1[i]), 1);
			}
			
			// get this key's value from each object
			var val1 = obj1[keys1[i]];
			var val2 = obj2[keys1[i]];
			
			// if val1 is an object
			if (this.isObject(val1, true)) {
				// if val2 is an object
				if (this.isObject(val2, true)) {
					// compare objects
					var submodified = this.getModifiedAccessors(val1, val2, accessor);
					for (var m = 0; m < submodified.length; m++) modified.push(submodified[m]);
				} else {
					// val1 is object, val2 is not
					modified.push(accessor);
				}
			} else {
				// if val2 is an object
				if (this.isObject(val2, true)) {
					// val2 is object, val1 is not
					modified.push(accessor);
				} else {
					// neither is object
					if (val1 !== val2) {
						modified.push(accessor);
					}
				}
			}
		}
		
		// add each remaining key from keys2 (since they did not appear in keys1)
		for (var k = 0; k < keys2.length; k++) {
			modified.push((prefix ? prefix : '') + (prefix && !this.isIndex(keys2[k]) ? '.' : '') +
				(this.isIndex(keys2[k]) ? ('[' + keys2[k] + ']') : keys2[k]));
		}
		
		return modified;
	}
	
	getNumeric(str, def) {
		return this.isNumeric(str) ? +str : +def;
	}
	
	getOptionalLength(str) {
		return this.isEmpty(str) ? 0 : str.length;
	}

	getParentMatchPath() {
		var lastSlashIdx = -1;
		
		if (this.props.match) {
			// find last slash
			lastSlashIdx = this.props.match.path.lastIndexOf('/');
		
			// if the slash is the last character, find the second-to-last slash
			if (lastSlashIdx === this.props.match.path.length - 1) {
				lastSlashIdx = this.props.match.path.substring(0, this.props.match.path.length - 1).lastIndexOf('/');
			}
		}
		
		if (lastSlashIdx === -1) return this.props.match;
		return this.props.match.path.substring(0, lastSlashIdx + 1);
	}

	getSelectList(data, valueProperty, labelProperty)
	{
		var selectList = [{ value: null, label: 'Select...' }];
		
		for (var i = 0; i < data.length; i++) {
			selectList.push({
				value: data[i][valueProperty],
				label: data[i][labelProperty]
			});
		}
		
		return selectList;
	}
	
	getUser() {
		if (this.props.parent) {
			return this.props.parent.getUser();
		} else {
			return this.state.user;
		}
	}
	
	includesIgnoreCase(str, substr) {
		return (!this.isEmpty(str) && !this.isEmpty(substr) &&
			('' + str).toUpperCase().includes(('' + substr).toUpperCase()) &&
			('' + str).toLowerCase().includes(('' + substr).toLowerCase()));
	}	
	
	isDate(date) {
		return date instanceof Date && !isNaN(date.valueOf())
	}
	
	isDevelopment() {
		return this.getConfig().reactHost === 'http://localhost:3000';
	}

	/* for our purposes, "Empty" includes strings that are all whitespace */
	isEmpty(s) {
		return s === undefined || s === null || /^\s*$/.test('' + s);
	}
	
	isFunction(functionToCheck) {
		return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
	}
	
	isIndex(i) {
		// positive integers only
		return /^\d+$/.test('' + i);
	}
	
	isNumeric(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	isObject(o, treatArraysAsObjects) {
		if (treatArraysAsObjects) {
			return typeof o === 'object' && o !== null;
		} else {
			// for our purposes exclude arrays by default even though they are technically objects
			return typeof o === 'object' && o !== null && !Array.isArray(o)
		}
	}
	
	isProduction() {
		return this.getConfig().reactHost === 'https://chemnet.berkeley.edu';
	}
	
	isTest() {
		return this.getConfig().reactHost === 'https://chemtest.berkeley.edu';
	}
	
	join(delim, ...strs) {
		var joined = "";
		for (var i = 0; i < strs.length; i++) {
			if (!this.isEmpty(strs[i])) {
				if (joined.length > 0) joined += delim;
				joined += strs[i];
			}
		}
		return joined;
	}
	
	js2mvc(data, columns) {
		if (Array.isArray(data)) {
			for (var r = 0; r < data.length; r++) {
				this.js2mvcOneRow(data[r], columns);
			}
			return data;
		} else {
			return this.js2mvcOneRow(data, columns);
		}
	}
	
	js2mvcOneRow(data, columns) {
		var a;
		for (var c = 0; c < columns.length; c++) {
			var accessors = columns[c].accessors ? columns[c].accessors : [columns[c].accessor];			
			if (columns[c].type === 'checkbox') {
				for (a = 0; a < accessors.length; a++) {				
					var bval = this.getByAccessor(data, accessors[a]);
					var format = columns[c].format || '';
					if (format === '1/0') {
						this.setByAccessor(data, accessors[a], bval ? 1 : 0);
					} else {
						this.setByAccessor(data, accessors[a], bval ? 'Y' : null);
					}
				}
			} else if (columns[c].type === 'date' || columns[c].type === 'datetime') {
				for (a = 0; a < accessors.length; a++) {
					var dateval = this.getByAccessor(data, accessors[a]);
					dateval = this.dateTimeToMVC(this.getDate(dateval));
					this.setByAccessor(data, accessors[a], dateval);
				}
			} else if (columns[c].type === 'time') {
				for (a = 0; a < accessors.length; a++) {
					var timeval = this.getDate(this.getByAccessor(data, accessors[a]));
					if (timeval !== null) {
						var hours = timeval.getHours();
						var minutes = timeval.getMinutes();
						minutes = minutes < 10 ? ('0' + minutes) : ('' + minutes);
						var ampm = '';
						if (hours >= 12) {
							// convert 24 hour time to 12 hour time
							ampm = 'pm';
							if (hours > 12) hours -= 12;
						} else {
							// 0 hours is 12 am
							ampm = 'am';
							if (hours === 0) hours = 12;
						}
						this.setByAccessor(data, accessors[a], hours + ':' + minutes + ampm);
					} else {
						this.setByAccessor(data, accessors[a], null);
					}
				}
			}
		}
		return data;
	}

	loadingOverlay(showOverlay, callback) {
		if (this.props.parent) {
			this.props.parent.loadingOverlay(showOverlay, callback);
		} else {
			this.mergeState({ overlay: showOverlay }, callback);
		}
	}

	logout(event) {
		if (this.props.user || this.state.user) {
			var self = this;
			
			$.ajax({
				type: 'POST',
				url: this.getConfig().host + '/Home/LogOut',
				data: { caluid: this.props.user ? this.props.user.uid : this.state.user.uid }
			}).always(function () {
				window.sessionStorage.clear();
				// POS sites relocate to themselves, all others go to CAS logout
				if (self.includesIgnoreCase(window.location, '/HeLAD/CCS')) {
					window.location = self.getConfig().reactHost + '/HeLAD/CCS';
				} else if (self.includesIgnoreCase(window.location, '/Stores/Checkout')) {
					window.location = self.getConfig().reactHost + '/Stores/Checkout';
				} else {
					window.location = 'https://' + self.getAuthServer() + '/cas/logout?url=' + self.getConfig().reactHost;
				}
			});
		} else if (this.props.parent) {
			this.props.parent.logout(event);
		} else {
			// this should never happen!
			console.log(this.props);
		}
	}

	mergeObject(oldObj, newObj) {
		var self = this;
		// for each key in the old object
		Object.keys(oldObj).forEach(function(key) {
			// if the key is not defined in the new object
			if (newObj[key] === undefined) {
				// add value to new object
				newObj[key] = oldObj[key];
			} else if (self.isObject(oldObj[key]) && self.isObject(newObj[key])) {
				// call recursively on child objects
				self.mergeObject(oldObj[key], newObj[key]);
			}
		});
		
		return newObj;
	}
	
	mergeObjectWithArrays(oldObj, newObj) {
		var self = this;
		// for each key in the old object
		Object.keys(oldObj).forEach(function(key) {
			// if the key is not defined in the new object
			// NOTE temporary hack to make this work for ChemEdit - treat nulls as undefined
			if (newObj[key] === undefined || newObj[key] === null) {
				// add value to new object
				newObj[key] = oldObj[key];
			} else if (Array.isArray(oldObj[key]) && Array.isArray(newObj[key])) {
				// replace old array with new array; no action required
			} else if (self.isObject(oldObj[key]) && self.isObject(newObj[key])) {
				// call recursively on child objects
				self.mergeObjectWithArrays(oldObj[key], newObj[key]);
			}
		});
		
		return newObj;
	}

	mergeObjectWithExclusions(oldObj, newObj, exclusions) {
		var self = this;
		// for each key in the old object
		Object.keys(oldObj).forEach(function(key) {
			if (!exclusions.includes(key)) {
				// if the key is not defined in the new object
				if (newObj[key] === undefined) {
					// add value to new object
					newObj[key] = oldObj[key];
				} else if (self.isObject(oldObj[key]) && self.isObject(newObj[key])) {
					// call recursively on child objects
					self.mergeObject(oldObj[key], newObj[key]);
				}
			}
		});
		
		return newObj;
	}

	mergeState(newState, callback) {
		this.mergeObject(this.state, newState);
		this.setState(newState, callback);
	}
  
	mergeStateWithArrays(newState, callback) {
		this.mergeObjectWithArrays(this.state, newState);
		this.setState(newState, callback);
	}
	
	mergeStateWithExclusions(newState, exclusions, callback) {
		this.mergeObjectWithExclusions(this.state, newState, exclusions);
		this.setState(newState, callback);
	}

	midnight(dt) {
		var mdt = new Date(this.getDate(dt));
		mdt.setHours(0,0,0,0);
		return mdt;
	}
	
	mvc2js(data, columns) {
		if (Array.isArray(data)) {
			for (var r = 0; r < data.length; r++) {
				this.mvc2jsOneRow(data[r], columns);
			}
			return data;
		} else {
			return this.mvc2jsOneRow(data, columns);
		}
	}
	
	mvc2jsOneRow(data, columns) {
		var a;
		for (var c = 0; c < columns.length; c++) {
			var accessors = columns[c].accessors ? columns[c].accessors : [columns[c].accessor];
			if (columns[c].type === 'checkbox') {
				for (a = 0; a < accessors.length; a++) {
					var bval = this.getByAccessor(data, accessors[a]);
					var format = columns[c].format || '';
					if (format === '1/0') {
						this.setByAccessor(data, accessors[a], bval === 1);
					} else {
						this.setByAccessor(data, accessors[a], bval === 'Y');
					}
				}
			} else if (columns[c].type === 'date' || columns[c].type === 'datetime') {
				for (a = 0; a < accessors.length; a++) {
					var dateval = this.getByAccessor(data, accessors[a]);
					dateval = this.dateTimeToMVC(this.getDate(dateval));
					this.setByAccessor(data, accessors[a], dateval);
				}
			} else if (columns[c].type === 'time') {
				for (a = 0; a < accessors.length; a++) {
					// 12 hour format:
					// ---------------
					// 1	(0?[0-9]|1[0-2])	hours 0-9 with optional leading 0, and 10-12
					// 2	:([0-5][0-9])		minutes :00 through :59
					// 3	(?::([0-5][0-9]))	optional seconds :00 through :59
					// 4	\s*(am|pm)?			optional AM or PM with optional leading whitespace
					//
					// 24 hour format:
					// ---------------
					// 5	(1[3-9]|2[0-3])		hours 13-23
					// 6	:([0-5][0-9])		minutes :00 through :59
					// 7	(?::([0-5][0-9]))	optional seconds :00 through :59
					var timeval = ('' + this.getByAccessor(data, accessors[a])).trim();
					var timeMatch = timeval.match(/^(?:(0?[0-9]|1[0-2]):([0-5][0-9])(?::([0-5][0-9]))?\s*(am|pm)?)|(?:(1[3-9]|2[0-3]):([0-5][0-9])(?::([0-5][0-9]))?)$/i);
					if (timeMatch) {
						var hours, minutes, seconds;
						timeval = new Date();						
						if (timeMatch[1]) {
							// 12 hour time
							hours = +timeMatch[1];
							if (('' + timeMatch[4]).match(/^pm$/i)) {
								// 12 pm is already 24 hour time
								if (hours < 12) hours += 12;
							} else {
								// 12 am is 0 in 24 hour time
								if (hours === 12) hours = 0;
							}
							minutes = +timeMatch[2];
							seconds = timeMatch[3] ? +timeMatch[3] : 0;
						} else {
							// 24 hour time
							hours = +timeMatch[5];
							minutes = +timeMatch[6];
							seconds = timeMatch[7] ? +timeMatch[7] : 0;
						}
						timeval.setHours(hours, minutes, seconds);
						timeval = this.dateTimeToMVC(timeval);
						this.setByAccessor(data, accessors[a], timeval);
					} else {
						this.setByAccessor(data, accessors[a], null);
					}
				}
			}
		}
		return data;
	}
	
	nextDay(dt) {
		var nextdt = this.midnight(dt);
		nextdt.setDate(nextdt.getDate() + 1);
		return nextdt;
	}
	
	nextDayMVC(dt) {
		return this.dateTimeToMVC(this.nextDay(dt));
	}
	
	numbersAreEqual(a, b) {
		if (!this.isNumeric(a) || !this.isNumeric(b)) return false;
		return (+a === +b);
	}

	objectsAreEqual(obj1, obj2) {
		
		// get list of keys for each object
		var keys1 = Object.keys(obj1);
		var keys2 = Object.keys(obj2);

		// if the number of keys doesn't match, they can't be equal
		if (keys1.length !== keys2.length) {
			//console.log('keys length not equal');
			//console.log(keys1);
			//console.log(keys2);
			return false;
		}
		
		// for each key in obj1
		for (var i = 0; i < keys1.length; i++) {
			// if this key isn't in obj2, they can't match
			if (!keys2.includes(keys1[i])) {
				//console.log('key ' + keys1[i] + ' not found in keys2');
				//console.log(keys2);
				return false;
			}
			
			// get this key's value from each object
			var val1 = obj1[keys1[i]];
			var val2 = obj2[keys1[i]];
			
			// if val1 is an object
			if (this.isObject(val1)) {
				// if val2 is an object
				if (this.isObject(val2)) {
					// if the objects aren't equal
					if (!this.objectsAreEqual(val1, val2)) return false;
				} else {
					// val1 is object, val2 is not
					//console.log('val2 is "' + val2 + '" but val1 is object');
					//console.log(val1);
					return false;
				}
			} else {
				// if val2 is an object
				if (this.isObject(val2)) {
					// val2 is object, val1 is not
					//console.log('val1 is "' + val1 + '" but val2 is object');
					//console.log(val2);
					return false;
				} else {
					// neither is object
					if (val1 !== val2) {
						//console.log('"' + val1 + '" = "' + val2 + '"');
						return false;
					}
				}
			}
		}
		
		return true;
	}
	
	pathJoin(a, b) {
		if (!a) return b;
		if (!b) return a;
		
		// coerce to strings just in case
		a = a + '';
		b = b + '';
		
		// if a doesn't end with a slash, add one
		if (a.length === 0 || a[a.length - 1] !== '/') a += '/';
		
		// if b starts with a slash, remove it
		if (b.length > 0 && b[0] === '/') b = b.substring(1);

		return a + b;
	}
	
	processData(columns, data) {
		var processed = [];
		
		for (var row = 0; row < data.length; row++) {
			var processedRow = {};
			for (var col = 0; col < columns.length; col++) {
				if (columns[col].accessor !== 'id') {
					if (columns[col].Cell) {
						// create props object
						var props = {
							parent: this,
							row: { values: data[row] },
							value: data[row][columns[col].accessor]
						};
						processedRow[columns[col].accessor] = this.textContent(columns[col].Cell(props));
					} else {
						processedRow[columns[col].accessor] = data[row][columns[col].accessor];
					}
				}
			}
			processed.push(processedRow);
		}
		
		return processed;
	}
	
	quoteStringArray(strs) {
		if (Array.isArray(strs)) {
			for (var i = 0; i < strs.length; i++) {
				if (strs[i] !== null && strs[i] !== undefined) {
					strs[i] = "'" + strs[i].replace("'", "''") + "'";
				}
			}
		}
	}
	
	removeColumnsByAccessor(columns, accessor) {
		var accessors = Array.isArray(accessor) ? accessor : [ accessor ];
		var newColumns = this.copyColumns(columns);
		for (var i = newColumns.length - 1; i >= 0; i--) {
			if (accessors.includes(newColumns[i].accessor)) {
				newColumns.splice(i, 1);
			}
		}
		
		return newColumns;
	}

	resolveEntity(contextAndEntity) {
		var context = 'COLLEGE';
		var entity = contextAndEntity;
		var dotIdx = entity.indexOf('.');
		var path = '/Home/Search';
		if (dotIdx >= 0) {
			context = entity.substring(0, dotIdx).toUpperCase();
			entity = entity.substring(dotIdx + 1);
			
			if (context === 'RECHARGES') {
				path = '/CRS/Search';
			} else if (context === 'ADMISSIONS') {
				path = '/GradOffice/Search';
			} else if (context === 'LIQUID_AIR' || context === 'DEMURRAGE') {
				path = '/HeLAD/Search';
			} else if (context === 'STORES') {
				path = '/Stores/Search';
			} else if (context === 'REUSE') {
				path = '/Reuse/Search';
			}
		}	
		return {
			context: context,
			name: entity,
			path: path
		};
	}
	
	search(searchProps, callback) {
		var self = this;
		var user = this.getUser();
		var entity = this.resolveEntity(searchProps.query.entity);
		
		if (user) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + entity.path,
				data: { 
					__RequestVerificationToken: user.antiForgeryToken,
					distinct: searchProps.query.distinct,
					columns: searchProps.query.columns,
					context: entity.context,
					entity: entity.name,
					search: searchProps.query.search,
					order: searchProps.query.order,
					pageNumber: searchProps.pageNumber ? searchProps.pageNumber : -1,
					pageSize: searchProps.pageSize ? searchProps.pageSize : -1,
				}
			}).done(function (data) {
				if (data.Success) {
					// for each row in the data
					for (var i = 0; i < data.Data.length; i++) {
						// if there is not already an id column, add one
						if (data.Data[i].id === undefined) data.Data[i].id = i;
						// if there is not already a drag column, add one
						if (data.Data[i].drag === undefined) data.Data[i].drag = i;
					}
					
					if (callback) callback(data);
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}
	
	setByAccessor(data, accessor, value) {
		if (Array.isArray(accessor)) {
			for (var a = 0; a < accessor.length; a++) {
				this.setByAccessorSingle(data, accessor[a], value[a]);
			}			
		} else {
			this.setByAccessorSingle(data, accessor, value);
		}
		
		return data;
	}
	
	setByAccessorSingle(data, accessor, newValue) {
		var idmatch, nextmatch, idx;
		
		// create regex for identifier (greatly simplified)
		var regex = new RegExp(/^([a-zA-Z_$][a-zA-Z0-9_]*)(?:\[((?:[1-9][0-9]*)|0)\])?$/);
		
		// split accessor into components
		var components = accessor.split('.');
		
		// start at top-level object
		var value = data;
		
		// for each component except the last one
		for (var i = 0; i < components.length - 1; i++) {
			// look for valid (greatly simplified) identifier
			idmatch = regex.exec(components[i]);
			
			// if this is a valid identifier
			if (idmatch) {
				// if there is an array index
				if (idmatch[2]) {
					// create the array if it doesn't exist
					if (!value[idmatch[1]]) value[idmatch[1]] = [];
					
					// use array as new value
					value = value[idmatch[1]];

					// get array index
					idx = +idmatch[2];
					
					// add nulls until array is long enough
					while (value.length <= idx) value.push(null);
					
					// if there isn't an object at the index we want
					if (!value[idx]) {
						// look ahead to next component
						nextmatch = regex.exec(components[i + 1]);
					
						// if next component has an array index
						if (nextmatch[2]) {
							// initialize next level with an empty array
							value[idx] = [];
						} else {
							// initialize next level with an empty object
							value[idx] = {};
						}
					}
					
					// shift to next level
					value = value[idx];
				} else {
					// if the property we're looking for does not exist
					if (!value[idmatch[1]]) {
						// look ahead to next component
						nextmatch = regex.exec(components[i + 1]);
					
						// if next component has an array index
						if (nextmatch[2]) {
							// initialize next level with an empty array
							value[idmatch[1]] = [];
						} else {
							// initialize next level with an empty object
							value[idmatch[1]] = {};
						}
					}	

					// shift to next level
					value = value[idmatch[1]];
				}
			} else {
				// invalid identifier, can't go any farther
				break;
			}
		}
		
		// match identifier for last level
		idmatch = regex.exec(components[components.length - 1]);
		
		// if this is a valid identifier
		if (idmatch) {
			// if there is an array index
			if (idmatch[2]) {
				// create the array if it does not exist
				if (!value[idmatch[1]]) value[idmatch[1]] = [];
				
				// use array as new value
				value = value[idmatch[1]];
				
				// get array index
				idx = +idmatch[2];
				
				// add nulls until array is long enough
				while (idx >= value.length) value.push(null);
				
				// set value at index
				value[idx] = newValue;
			} else {
				// set property value
				value[idmatch[1]] = newValue;
			}
		}
		
		return data;
	}

	setIDColumn(data, accessor) {
		for (var i = 0; i < data.length; i++) {
			data[i].id = this.getByAccessor(data[i], accessor);
		}
	}

	setUser(user) {
		if (this.props.parent) {
			this.props.parent.setUser(user);
		} else {
			this.mergeState({ user: user });
		}
	}		
	
	showAbout() {
		var self = this;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/GetVersion',
			data: { __RequestVerificationToken: self.props.user.antiForgeryToken }
		}).done(function (data) {
			if (data.Success) {
				var env = self.isProduction() ? 'Production' : (self.isTest() ? 'Test' : 'Development');
				self.showAlert('About',
					'For further assistance please contact John Borland<br />' +
					'jborland@berkeley.edu<br />' +
					'510-643-1706<br />' +
					'<br />' +
					'© 2022 UC Regents<br />' +
					'<br />' +
					'Client version ' + self.getConfig().version + '<br />' +
					'Server version ' + data.Version + '<br />' +
					env + ' environment<br /><br />' +
					'<img src="' + self.getConfig().host + '/Content/Icons/Pdf.png" alt="PDF Icon" /> PDF Icon (modified) ' +
					'By <a rel="nofollow" class="external text" href="https://www.fatcow.com/">FatCow Web Hosting</a> - <a rel="nofollow" class="external free" href="https://www.fatcow.com/free-icons/">https://www.fatcow.com/free-icons/</a>, <a href="https://creativecommons.org/licenses/by/3.0/us/deed.en" title="Creative Commons Attribution 3.0 us">CC BY 3.0 us</a>, <a href="https://commons.wikimedia.org/w/index.php?curid=11529192">Link</a>');
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	showAlert(title, content, callback) {
		this.props.parent.showAlert(title, content, callback);
	}
	
	showAuthAlert(retcode) {
		this.props.parent.showAuthAlert(retcode);
	}
	
	showConfirmation(content) {
		this.props.parent.showConfirmation(content);
	}
	
	showOKCancel(title, content, callback) {
		this.props.parent.showOKCancel(title, content, callback);
	}
	
	startsWithIgnoreCase(str, prefix) {
		return (!this.isEmpty(str) && !this.isEmpty(prefix) &&
			('' + str).toUpperCase().startsWith(('' + prefix).toUpperCase()) &&
			('' + str).toLowerCase().startsWith(('' + prefix).toLowerCase()));
	}
	
	textContent(elem) {
	  if (!elem) {
		return '';
	  }
	  if (typeof elem === 'string') {
		return elem;
	  }
	  const children = elem.props && elem.props.children;
	  if (children instanceof Array) {
		return children.map(this.textContent).join('');
	  }
	  return this.textContent(children);
	}
	
	unFormatCurrency(str) {
		if (this.isNumeric(str)) return +str;
		if (this.isEmpty(str)) return 0;
		return +str.replace(/,/g, '');
	}
	
	validate(columns, data, columns2, data2) {
		var i;
		var missingColumns = "";
		var tooLong = "";
		
		// for each column
		for (i = 0; i < columns.length; i++) {
			// if the column is required and the data is missing
			if (columns[i].required && this.isEmpty(this.getByAccessor(data, columns[i].accessor))) {
				// add column to list of missing columns
				if (missingColumns.length > 0) missingColumns += ', ';
				missingColumns += columns[i].Header;
			}
			
			// if there is a maxlength and the data is too long
			if (!this.isEmpty(columns[i].maxlength) && this.getOptionalLength(this.getByAccessor(data, columns[i].accessor)) > columns[i].maxlength) {
				// add column to the list of too-long columns
				if (tooLong.length > 0) tooLong += ', ';
				tooLong += columns[i].Header + ' (max length ' + columns[i].maxlength + ')';
			}
		}
		
		// if a second validation set was included
		if (columns2 && data2) {
			// for each column
			for (i = 0; i < columns2.length; i++) {
				// if the column is required and the data is missing
				if (columns2[i].required && this.isEmpty(this.getByAccessor(data2, columns2[i].accessor))) {
					// add column to list of missing columns
					if (missingColumns.length > 0) missingColumns += ', ';
					missingColumns += columns2[i].Header;
				}
				
				// if there is a maxlength and the data is too long
				if (!this.isEmpty(columns2[i].maxlength) && this.getOptionalLength(this.getByAccessor(data2, columns2[i].accessor)) > columns2[i].maxlength) {
					// add column to the list of too-long columns
					if (tooLong.length > 0) tooLong += ', ';
					tooLong += columns2[i].Header + ' (max length ' + columns2[i].maxlength + ')';
				}
			}
		}
		
		if (missingColumns.length > 0) {
			if (tooLong.length > 0) {
				return 'The following fields are required: ' + missingColumns +
					'; The following fields exceed maximum length: ' + tooLong;
			} else {
				return 'The following fields are required: ' + missingColumns;
			}
		} else {
			if (tooLong.length > 0) {
				return 'The following fields exceed maximum length: ' + tooLong;
			} else {
				return null;
			}
		}
	}
}

class AjaxWrapper {
	constructor(chemCmpt, ajaxArgs) {
		this.chemCmpt = chemCmpt;
		this.ajaxArgs = ajaxArgs;
	}
	
	ajax() {
		// add token to arguments
		if (!this.ajaxArgs.data.__RequestVerificationToken) {
			var user = this.chemCmpt.getUser();
			if (user) this.ajaxArgs.data.__RequestVerificationToken = user.antiForgeryToken;
		}
		
		// create deferred object and self
		this.deferred = $.Deferred();
		var self = this;
		
		if (this.ajaxArgs.overlay) {
			// invoke ajax with loading overlay
			this.chemCmpt.loadingOverlay(true, () => {
				$.ajax(self.ajaxArgs).done((data) => {
					self.deferred.resolve(data);
				}).fail((jqXHR, textStatus, errorThrown) => {
					self.deferred.reject(jqXHR, textStatus, errorThrown);
				});
			});
		} else {
			// invoke ajax without loading overlay
			$.ajax(self.ajaxArgs).done((data) => {
				self.deferred.resolve(data);
			}).fail((jqXHR, textStatus, errorThrown) => {
				self.deferred.reject(jqXHR, textStatus, errorThrown);
			});			
		}
		return this;
	}
	
	done(doneFn) {
		var self = this;
		
		this.deferred.done((data) => {
			if (self.ajaxArgs.overlay) {
				// stop loading overlay before doneFn
				self.chemCmpt.loadingOverlay(false, () => {
					doneFn(data);
				});
			} else {
				// not necessary to stop loading overlay
				doneFn(data);
			}
		});
		return this;
	}
	
	fail(failFn) {
		var self = this;
		
		this.deferred.fail((jqXHR, textStatus, errorThrown) => {
			if (self.ajaxArgs.overlay || (jqXHR && jqXHR.status === 401) || self.isAntiForgeryError(jqXHR)) {
				// stop loading overlay before failFn
				self.chemCmpt.loadingOverlay(false, () => {
					if (jqXHR && jqXHR.status === 401) {
						self.chemCmpt.showAuthAlert(401);
					} else if (self.isAntiForgeryError(jqXHR)) {
						self.chemCmpt.showAuthAlert(500);
					} else {
						failFn(jqXHR, textStatus, errorThrown);
					}
				});
			} else {
				// not necessary to stop loading overlay
				failFn(jqXHR, textStatus, errorThrown);
			}
		});
		return this;
	}
	
	isAntiForgeryError(jqXHR) {
		return jqXHR && jqXHR.status === 500 && jqXHR.responseText && (
			jqXHR.responseText.indexOf('The anti-forgery cookie token and form field token do not match.') >= 0 ||
			jqXHR.responseText.indexOf('The provided anti-forgery token was meant for a different claims-based user than the current user.') >= 0
		);
	}
}

export default ChemComponent;
