import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import Tabs from '../Tabs';

class Search extends ChemComponent {	
	constructor(props) {
		super(props);
		
		var search = this.copyObject(defaultSearch);
		var initBottleList = this.copyTable(bottleList);
		var searchCount = 0;
		var showSearch = true;
		
		if (typeof(Storage) !== 'undefined') {
			var storedSearch = localStorage.getItem('Reuse_Search_search');
			if (storedSearch) {
				storedSearch = JSON.parse(storedSearch);
				this.mergeObject(search, storedSearch);
				search = storedSearch;
				initBottleList.ajax.data = { search: this.copyObject(search) };
				initBottleList.hold = false;
				initBottleList.ajax.data.search.COMPLEX = this.getComplexArray(initBottleList.ajax.data.search);
				searchCount = null;
				showSearch = false;
			}
		}

		this.state = {
			bottleList: initBottleList,
			search: search,
			showSearch: showSearch,
			searchCount: searchCount,
			renderKey: 0
		};
	}
	
	componentDidMount() {
		// if location has not been confirmed
		if (!this.props.locationConfirmed) {
			// redirect user back to home page
			this.props.navigate('/Reuse/Home');
		}
	}

	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onNewSearch(event) {
		this.mergeState({
			search: this.copyObject(defaultSearch),
			showSearch: true,
			renderKey: this.state.renderKey + 1
		});
	}

	onExpandSearch(event) {
		this.mergeState({
			showSearch: true,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();		
	}

	onSubmitSearch(event) {
		var newBottleList = this.copyTable(bottleList);
		newBottleList.ajax.data = {
			search: this.copyObject(this.state.search)
		};
		newBottleList.hold = false;
		newBottleList.ajax.data.search.COMPLEX = this.getComplexArray(newBottleList.ajax.data.search);
		this.mergeState({ 
			bottleList: newBottleList,
			showSearch: false,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('Reuse_Search_search', JSON.stringify(this.state.search));
		}
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	getComplexArray(search) {
		var complex = [];
		
		if (search.BF4) complex.push('BF4');
		if (search.BH3) complex.push('BH3');
		if (search.Br2) complex.push('Br2');
		if (search.C2H4O) complex.push('C2H4O');
		if (search.C2H4O2) complex.push('C2H4O2');
		if (search.C4H6O6) complex.push('C4H6O6');
		if (search.C4H8O) complex.push('C4H8O');
		if (search.C6H13N) complex.push('C6H13N');
		if (search.CH4SO3) complex.push('CH4SO3');
		if (search.CO) complex.push('CO');
		if (search.ClO4) complex.push('ClO4');
		if (search.H2CO3) complex.push('H2CO3');
		if (search.H2O) complex.push('H2O');
		if (search.H2O2) complex.push('H2O2');
		if (search.H2SO4) complex.push('H2SO4');
		if (search.H3PO4) complex.push('H3PO4');
		if (search.HBr) complex.push('HBr');
		if (search.HBr3) complex.push('HBr3');
		if (search.HCO2) complex.push('HCO2');
		if (search.HCl) complex.push('HCl');
		if (search.HF) complex.push('HF');
		if (search.HNO3) complex.push('HNO3');
		if (search.LiBr) complex.push('LiBr');
		if (search.LiCl) complex.push('LiCl');
		
		return complex;
	}

	viewBottle(bottleId) {
		this.props.setBottleEditable(false);
		this.props.navigate('/Reuse/Bottles/' + bottleId);
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	renderElement(idx) {
		return(
			<Container fluid>
				<Row>
					<Col xs={5} style={{ marginBottom: '14px' }}>
						<Form.Control as="select" value={this.getByAccessor(this.state.search, 'ELEMENT[' + idx + ']')}
							onChange={(event) => this.onChangeSearch('ELEMENT[' + idx + ']', event.target.value)}>
							{elementList.map((option, optionIdx) => {
								return (<option key={optionIdx} value={option.value}>{option.label}</option>);
							})}
						</Form.Control>
					</Col>
					<Col xs={2} style={{ marginBottom: '14px' }}>
						<Form.Control as="select" value={this.getByAccessor(this.state.search, 'RELATION[' + idx + ']')}
							onChange={(event) => this.onChangeSearch('RELATION[' + idx + ']', event.target.value)}>
							{comparators.map((option, optionIdx) => {
								return (<option key={optionIdx} value={option.value}>{option.label}</option>);
							})}
						</Form.Control>
					</Col>
					<Col xs={5} style={{ marginBottom: '14px' }}>
						<Form.Control type="text" value={this.getByAccessorForInput(this.state.search, 'NUMBER[' + idx + ']')}
							onChange={(event) => this.onChangeSearch('NUMBER[' + idx + ']', event.target.value)} />
					</Col>
				</Row>
			</Container>
		);
	}
	
	renderFormula(formula) {
		var self = this;
		if (this.isEmpty(formula)) return null;
		var elements = formula.match(/([A-Z][a-z]?)([0-9]*)/g);
		if (this.isEmpty(elements) || elements.length === 0) return null;
		return elements.map((element, key) => {
			var elementAndNumber = element.match(/^([A-Z][a-z]?)([0-9]*)$/);
			if (self.isEmpty(elementAndNumber[2])) {
				return (<React.Fragment key={key}>{elementAndNumber[1]}</React.Fragment>);
			} else {
				return (<React.Fragment key={key}>{elementAndNumber[1]}<sub>{elementAndNumber[2]}</sub></React.Fragment>);
			}
		});
	}
	
	renderFormulae(formulae) {
		if (this.isEmpty(formulae)) return '';
		var pmatch = formulae.match(/^\(((?:[a-zA-Z]+[0-9]*)+)\)n(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?$/);
		var match = formulae.match(/^((?:[a-zA-Z]+[0-9]*)+)(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?$/);
		if (pmatch) {
			return (<>({this.renderFormula(pmatch[1])})n{!this.isEmpty(pmatch[2]) && <> ● {pmatch[2]} {this.renderFormula(pmatch[3])}</>}{!this.isEmpty(pmatch[4]) && <> ● {pmatch[4]} {this.renderFormula(pmatch[5])}</>}</>);
		} else if (match) {
			return (<>{this.renderFormula(match[1])}{!this.isEmpty(match[2]) && <> ● {match[2]} {this.renderFormula(match[3])}</>}{!this.isEmpty(match[4]) && <> ● {match[4]} {this.renderFormula(match[5])}</>}</>);
		} else {
			return (<>{formulae}</>);
		}
	}
	
	renderComplex(formula1, formula2, formula3) {
		return (
			<Container fluid>
				<Row>
					<Col style={{ marginBottom: '14px' }}>
						<Form.Check style={{ paddingTop: '8px' }} type="checkbox"
							checked={this.getByAccessor(this.state.search, formula1)} label={this.renderFormula(formula1)}
							onClick={(event) => this.onChangeSearch(formula1, event.target.checked)} />
					</Col>
					<Col style={{ marginBottom: '14px' }}>
						<Form.Check style={{ paddingTop: '8px' }} type="checkbox"
							checked={this.getByAccessor(this.state.search, formula2)} label={this.renderFormula(formula2)}
							onClick={(event) => this.onChangeSearch(formula2, event.target.checked)} />
					</Col>
					<Col style={{ marginBottom: '14px' }}>
						<Form.Check style={{ paddingTop: '8px' }} type="checkbox"
							checked={this.getByAccessor(this.state.search, formula3)} label={this.renderFormula(formula3)}
							onClick={(event) => this.onChangeSearch(formula3, event.target.checked)} />
					</Col>
				</Row>
			</Container>
		);
	}
	
	renderSafetyIcon(chemid) {
		var safetyData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			chemid: chemid
		});
		return(
		  <form action={this.getConfig().host + '/Reuse/SafetyPage'} method='POST' target='_blank' style={{ textAlign: 'center', marginTop: '4px' }}>
			{safetyData.map((safetyFormField, index) => {
			  return (<input key={index} type='hidden' name={safetyFormField.name} value={safetyFormField.value} />);
			})}
			<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Safety Sheet</Popover.Header></Popover>}>
			  <input type='image' src={this.getConfig().host + '/Content/Icons/safety_page_sm.png'} alt="Safety Sheet" />
			</OverlayTrigger>
		  </form>
		);
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'Bottles',
			columns: exportColumns,
			search: this.state.search
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Bottles</div>
						{this.state.showSearch && <Tabs>
						  <div label='Bottle'>
							<ChemEdit parent={this} user={this.props.user} columns={bottleSearch} data={this.state.search}
							  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
						  </div>
						  <div label='Formula'>
							<ChemEdit parent={this} user={this.props.user} columns={formulaSearch} data={this.state.search}
							  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
						  </div>
						</Tabs>}
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						{this.state.showSearch ?
							<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button> :
							<Button id="expand" variant="warning" type="button" className="float-end" onClick={(event) => this.onExpandSearch(event)}>Modify Search</Button>
						}
						<Button id="newSearch" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.onNewSearch(event)}>New Search</Button> 
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Reuse_Bottles' table={this.state.bottleList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='150px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const defaultSearch = {
	CAS_NUMBER: '',
	CHEM_NAME: '',
	SHELF_CODE: '',
	BOTTLE_ID: '',
	BOTTLE_ID_GT: '',
	BOTTLE_ID_LT: '',
	CHEM_STATUS_IN: true,
	CHEM_STATUS_OUT: false,
	DELETE_FLAG: false,
	FORMULA: false,
	ELEMENT: ['C','H','O','N',null,null],
	RELATION: ['>=','>=','>=','>=','>=','>='],
	NUMBER: ['','','','','',''],
	EXCLUSION: true,
	EXCLUDE: [null, null],
	BF4: false,
	BH3: false,
	Br2: false,
	C2H4O: false,
	C2H4O2: false,
	C4H6O6: false,
	C4H8O: false,
	C6H13N: false,
	CH4SO3: false,
	CO: false,
	ClO4: false,
	H2CO3: false,
	H2O: false,
	H2O2: false,
	H2SO4: false,
	H3PO4: false,
	HBr: false,
	HBr3: false,
	HCO2: false,
	HCl: false,
	HF: false,
	HNO3: false,
	LiBr: false,
	LiCl: false,
	UNIT_ID: null,
	STORAGE_CODE: null,
	BOTTLE_SIZE: '',
	PURITY: '',
	PERSON_FNAME: '',
	PERSON_LNAME: '',
	CATALOG_NUMBER: '',
	PERSON_ID: null,
	PI_ID: null,
	CHEM_STATE: [],
	DATE_SHELVED_FROM: null,
	DATE_SHELVED_TO: null,
	DATE_MODIF: null,
	CHEM_CLASS: [],
	BRAND_ID: [],
	SEG_ID: [],
	STORAGE_ID: [],
	FORM_ID: [],
	GRADE_ID: [],
	CONTAINER_ID: [],
	OTHER_NOTES: '',
	COMMENT_ID: null,
};


const comparators = [
	{ value: '=', label: '=' },
	{ value: '<=', label: '≤' },
	{ value: '>=', label: '≥' }
];

const elementList = [
	{ value: null, label: 'Select...'},                
	{ value: 'Pb', label: 'Pb'},                
	{ value: 'Mo', label: 'Mo' },                
	{ value: 'Eu', label: 'Eu' },                
	{ value: 'W', label: 'W' },                
	{ value: 'Tl', label: 'Tl' },                
	{ value: 'Ca', label: 'Ca' },                
	{ value: 'Lu', label: 'Lu' },                
	{ value: 'Ho', label: 'Ho' },                
	{ value: 'Ce', label: 'Ce' },                
	{ value: 'B', label: 'B' },                
	{ value: 'Mn', label: 'Mn' },                
	{ value: 'Se', label: 'Se' },                
	{ value: 'C', label: 'C' },                
	{ value: 'Tm', label: 'Tm' },                
	{ value: 'Xe', label: 'Xe' },                
	{ value: 'Sr', label: 'Sr' },                
	{ value: 'Te', label: 'Te' },                
	{ value: 'Nd', label: 'Nd' },                
	{ value: 'Pt', label: 'Pt' },                
	{ value: 'Rh', label: 'Rh' },                
	{ value: 'Sb', label: 'Sb' },                
	{ value: 'F', label: 'F' },                
	{ value: 'I', label: 'I' },                
	{ value: 'Si', label: 'Si' },                
	{ value: 'Br', label: 'Br' },                
	{ value: 'Cl', label: 'Cl' },                
	{ value: 'H', label: 'H' },                
	{ value: 'O', label: 'O' },                
	{ value: 'Ni', label: 'Ni' },                
	{ value: 'Pr', label: 'Pr' },                
	{ value: 'In', label: 'In' },                
	{ value: 'As', label: 'As' },                
	{ value: 'Th', label: 'Th' },                
	{ value: 'Rb', label: 'Rb' },                
	{ value: 'Y', label: 'Y' },                
	{ value: 'Cs', label: 'Cs' },                
	{ value: 'Cr', label: 'Cr' },                
	{ value: 'Sn', label: 'Sn' },                
	{ value: 'N', label: 'N' },                
	{ value: 'P', label: 'P' },                
	{ value: 'Er', label: 'Er' },                
	{ value: 'Gd', label: 'Gd' },                
	{ value: 'Nb', label: 'Nb' },                
	{ value: 'Hf', label: 'Hf' },                
	{ value: 'Yb', label: 'Yb' },                
	{ value: 'Zr', label: 'Zr' },                
	{ value: 'Al', label: 'Al' },                
	{ value: 'Ti', label: 'Ti' },                
	{ value: 'S', label: 'S' },                
	{ value: 'Sm', label: 'Sm' },                
	{ value: 'Ar', label: 'Ar' },                
	{ value: 'Re', label: 'Re' },                
	{ value: 'Ta', label: 'Ta' },                
	{ value: 'Bi', label: 'Bi' },                
	{ value: 'Ru', label: 'Ru' },                
	{ value: 'K', label: 'K' },                
	{ value: 'Ba', label: 'Ba' },                
	{ value: 'Fe', label: 'Fe' },                
	{ value: 'La', label: 'La' },                
	{ value: 'Hg', label: 'Hg' },                
	{ value: 'Tb', label: 'Tb' },                
	{ value: 'Au', label: 'Au' },                
	{ value: 'V', label: 'V' },                
	{ value: 'Li', label: 'Li' },                
	{ value: 'Ag', label: 'Ag' },                
	{ value: 'Cu', label: 'Cu' },                
	{ value: 'Zn', label: 'Zn' },                
	{ value: 'U', label: 'U' },                
	{ value: 'Cd', label: 'Cd' },                
	{ value: 'Ga', label: 'Ga' },                
	{ value: 'Pd', label: 'Pd' },                
	{ value: 'D', label: 'D' },                
	{ value: 'Na', label: 'Na' },                
	{ value: 'Mg', label: 'Mg' },                
	{ value: 'Co', label: 'Co' },                
	{ value: 'Ge', label: 'Ge' },                
	{ value: 'Be', label: 'Be' },                
	{ value: 'Os', label: 'Os' },                
	{ value: 'Dy', label: 'Dy' },                
	{ value: 'Ir', label: 'Ir' },                
	{ value: 'Sc', label: 'Sc' }
];

const bottleSearch = [
	{ Header: 'CAS Number', accessor: 'CAS_NUMBER', row: 0, col: 0 },
	{ Header: 'Name', accessor: 'CHEM_NAME', row: 0, col: 1 },
	{ Header: 'Bottle ID', accessor: 'BOTTLE_ID', row: 0, col: 2 },
	{ Header: 'In Reuse', accessor: 'CHEM_STATUS_IN', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Checked Out', accessor: 'CHEM_STATUS_OUT', type: 'checkbox', row: 1, col: 1 },
	{ Header: 'Purity (%)', accessor: 'PURITY', row: 1, col: 2 },
	{ Header: 'Bottle Size', accessor: 'BOTTLE_SIZE', row: 2, col: 0 },
	{ Header: 'Unit', accessor: 'UNIT_ID', type: 'select', row: 2, col: 1,
		options: { value: 'UNIT_ID', label: 'UNIT_NAME', entity: 'REUSE.UNIT_SIZE', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'State', accessor: 'CHEM_STATE', type: 'checklist', row: 2, col: 2,
		options: [{ value: 'liquid', label: 'Liquid' },{ value: 'solid', label: 'Solid' },{ value: 'gas', label: 'Gas' }]},
	{ Header: 'Catalog #', accessor: 'CATALOG_NUMBER', row: 3, col: 0 },
	{ Header: 'Brand', accessor: 'BRAND_ID', type: 'list', row: 3, col: 1,
		options: { value: 'BRAND_ID', label: 'BRAND_NAME', entity: 'REUSE.BRAND_SELECT_VW', order: ['SEPARATOR','SHOW_PREF','BRAND_NAME_UCASE']}},
	{ Header: 'Shelf Code', accessor: 'SHELF_CODE', row: 3, col: 2 },
	{ Header: 'Entered Reuse From', accessor: 'CREATE_DATE_FROM', type: 'date', row: 4, col: 1 },
	{ Header: 'Entered Reuse To', accessor: 'CREATE_DATE_TO', type: 'date', row: 4, col: 2 }
];

const formulaSearch = [
	{ Header: '<b>Search by Formula</b>', accessor: 'FORMULA', type: 'checkbox', row: 0, col: 0 },
	{ Editor: props => props.parent.renderComplex('BF4', 'CH4SO3', 'HBr'), row: 0, col: 2 },
	{ Editor: props => props.parent.renderElement(0), row: 1, col: 0 },
	{ Editor: props => props.parent.renderComplex('BH3', 'CO', 'HBr3'), row: 1, col: 2 },
	{ Editor: props => props.parent.renderElement(1), row: 2, col: 0 },
	{ Header: 'Exclude', accessor: 'EXCLUSION', type: 'checkbox', label: 'all other elements', row: 2, col: 1 },
	{ Editor: props => props.parent.renderComplex('Br2', 'ClO4', 'HCO2'), row: 2, col: 2 },
	{ Editor: props => props.parent.renderElement(2), row: 3, col: 0 },
	{ Header: 'Exclude', accessor: 'EXCLUDE[0]', type: 'select', options: elementList, row: 3, col: 1 },
	{ Editor: props => props.parent.renderComplex('C2H4O', 'H2CO3', 'HCl'), row: 3, col: 2 },
	{ Editor: props => props.parent.renderElement(3), row: 4, col: 0 },
	{ Header: 'Exclude', accessor: 'EXCLUDE[1]', type: 'select', options: elementList, row: 4, col: 1 },
	{ Editor: props => props.parent.renderComplex('C2H4O2', 'H2O', 'HF'), row: 4, col: 2 },
	{ Editor: props => props.parent.renderElement(4), row: 5, col: 0 },
	{ Editor: props => props.parent.renderComplex('C4H6O6', 'H2O2', 'HNO3'), row: 5, col: 2 },
	{ Editor: props => props.parent.renderElement(5), row: 6, col: 0 },
	{ Editor: props => props.parent.renderComplex('C4H8O', 'H2SO4', 'LiBr'), row: 6, col: 2 },
	{ Editor: props => props.parent.renderComplex('C6H13N', 'H3PO4', 'LiCl'), row: 7, col: 2 }
];

const bottleList = {
	ajax: { 
		url: '/Reuse/BottleSearch',
		data: null,
		accessor: 'Data'
	},
	hold: true,
	pageSize: 20,
	columns: [	
		{ Header: '', accessor: 'ITEM_ID', show: false },
		{ Header: 'Bottle ID', accessor: 'BOTTLE_ID', width: 70,
			Cell: props => <HashLink onClick={() => props.parent.viewBottle(props.row.values.ITEM_ID)}>{props.value}</HashLink> },
		{ Header: 'Name', accessor: 'CHEM_NAME', width: 300 },
		{ Header: 'CAS', accessor: 'CAS_NUMBER', width: 100 },
		{ Header: 'Formula', accessor: 'FORMULA', Cell: props => props.parent.renderFormulae(props.value), width: 200 },
		{ Header: props => <>Safety<br/>Sheet</>, accessor: 'CHEM_ID', Cell: props => props.parent.renderSafetyIcon(props.value), width: 60 },
		{ Header: 'Amount', accessor: 'AMOUNT', width: 70 },
		{ Header: 'Grade', accessor: 'GRADE', width: 200 },
		{ Header: 'Supplier', accessor: 'BRAND_NAME', width: 125 },
		{ Header: 'Purity', accessor: 'PURITY', width: 100 },
		{ Header: 'Form', accessor: 'FORM_NAME', width: 100, },
		{ Header: 'Shelf Code', accessor: 'SHELF_CODE', width: 100 },		
		{ Header: 'Status', accessor: 'STORAGE_STATUS', width: 125 }
	]
};

const exportColumns = [
	{ Header: 'Bottle ID', accessor: 'BOTTLE_ID', width: 14 },
	{ Header: 'Name', accessor: 'CHEM_NAME', width: 60 },
	{ Header: 'CAS', accessor: 'CAS_NUMBER', width: 20 },
	{ Header: 'Formula', accessor: 'FORMULA', width: 40 },
	{ Header: 'Chem ID', accessor: 'CHEM_ID', width: 14 },
	{ Header: 'Amount', accessor: 'AMOUNT', width: 14 },
	{ Header: 'Grade', accessor: 'GRADE', width: 40 },
	{ Header: 'Supplier', accessor: 'BRAND_NAME', width: 25 },
	{ Header: 'Purity', accessor: 'PURITY', width: 20 },
	{ Header: 'Form', accessor: 'FORM_NAME', width: 20, },
	{ Header: 'Shelf Code', accessor: 'SHELF_CODE', width: 20 },		
	{ Header: 'Status', accessor: 'STORAGE_STATUS', width: 25 }
];

export default withRouter(Search);