import ChemComponent from './ChemComponent';
import { Col, Container, Row } from 'react-bootstrap';
import { withRouter } from './withRouter';

class Restricted extends ChemComponent {

	componentDidMount() {
		// can't redirect during render so do it here
		if (!this.props.authorized && this.props.redirect) {
			this.props.navigate(this.props.redirect);
		}
	}

	componentDidUpdate() {
		// can't redirect during render so do it here
		if (!this.props.authorized && this.props.redirect) {
			this.props.navigate(this.props.redirect);
		}
	}

	render() {
		if (this.props.authorized) {
			return (<>{this.props.children}</>);
		} else {
			return (
				<Container fluid>
				  <Row>
					<Col xs={12} sm={11} md={10} lg={9} xl={8} style={{ paddingBottom: '20px', paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
						User is not authorized for this feature.
					</Col>
				  </Row>
				</Container>
			);
		}
	}
}

export default withRouter(Restricted)
