import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, InputGroup, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import ChemComponent from './ChemComponent';
import ChemTable from './ChemTable';
import DatePicker from 'react-datepicker';
import MultiColumn from './MultiColumn';
import NumPad from './NumPad';
import { Trash, ArrowsFullscreen, FullscreenExit, SlashCircle } from 'react-bootstrap-icons';
import 'react-datepicker/dist/react-datepicker.css';

class ChemEdit extends ChemComponent
{
	constructor(props) {
		super(props);
		
		// find max row, col for all cells
		var maxRow = 0;
		var maxCol = 0;
		var useGrid = true;
		for (var c = 0; c < this.props.columns.length; c++) {
			if (!this.isEmpty(this.props.columns[c].col) && !this.isEmpty(this.props.columns[c].row)) {
				if (this.props.columns[c].col > maxCol) maxCol = this.props.columns[c].col;
				if (this.props.columns[c].row > maxRow) maxRow = this.props.columns[c].row;
			} else {
				// if any are unspecified, assume single column
				useGrid = false;
			}
		}
		
		// if any row/col are specified, they all must be specified
		var nrows, ncols;
		if (useGrid) {
			nrows = maxRow + 1;
			ncols = maxCol + 1;
		} else {
			nrows = this.props.columns.length;
			ncols = 1;
		}
		
		var colWidth = Math.floor(12/ncols);
		var colPad = (12 - ncols*colWidth)/2;
		
		this.state = {
			editable: this.isEmpty(this.props.editable) ? true : this.props.editable,
			options: {},
			showDropdown: {},
			tableSelect: {},
			tableDictionary: {},
			labelColWidth: this.props.labelColWidth || 5,
			valueColWidth: this.props.valueColWidth || 7,
			nrows: nrows,
			ncols: ncols,
			colPad: colPad,
			colWidth: colWidth,
			useGrid: useGrid,
			expanded: null,
			renderKey: 0
		};
	}
	
	componentDidMount() {
		this.lookUpAllOptions();
	}
	
	lookUpAllOptions() {
		var newState = {
			options: {},
			tableDictionary: {}
		};
		this.lookUpOptions(newState, this.props.columns);	
	}
	
	getOrderColumnName(ordercol) {
		// if order column ends with ' desc'
		if (/ desc$/.test(ordercol)) {
			return ordercol.substring(0, ordercol.length - 5);
		} else {
			return ordercol;
		}
	}
	
	lookUpAccessors(search) {
		if (search.Children) {
			for (var i = 0; i < search.Children.length; i++) {
				this.lookUpAccessors(search.Children[i]);
			}
		} else if (search.StringAccessor) {
			search.StringValue = this.getByAccessor(this.props.data, search.StringAccessor);
		} else if (search.LongAccessor) {
			search.LongValue = this.getByAccessor(this.props.data, search.LongAccessor);
		} else if (search.IntAccessor) {
			search.IntValue = this.getByAccessor(this.props.data, search.IntAccessor);
		} else if (search.DateAccessor) {
			search.DateValue = this.getByAccessor(this.props.data, search.DateAccessor);
		} else if (search.DoubleAccessor) {
			search.DoubleValue = this.getByAccessor(this.props.data, search.DoubleAccessor);
		} else if (search.DecimalAccessor) {
			search.DecimalValue = this.getByAccessor(this.props.data, search.DecimalAccessor);
		} else if (search.LongListAccessor) {
			search.LongList = this.getByAccessor(this.props.data, search.LongListAccessor);
		}
	}
	
	lookUpOptions(newState, columns) {
		var self = this;
		var searches = [];
		var atLeastOneSearch = false;
		for (var i = 0; i < columns.length; i++) {
			var column = columns[i];

			// if this is a select/list/dropdown/table column
			if (column.type === 'select' || column.type === 'list' || column.type === 'listoptions' || column.type === 'dropdown' || 
				(column.type === 'table' && column.editable !== false) || column.type === 'checklist' || column.type === 'multicolumn') {
				// if this column requires a lookup
				if (column.options.entity) {
					var search = null;
					
					// if there is a search
					if (column.options.search) {
						// make a copy of the search
						search = JSON.parse(JSON.stringify(column.options.search));
						this.lookUpAccessors(search);
					}

					// construct column list to prevent SELECTed error
					var colnames = [ column.options.value ];
					var collabels = Array.isArray(column.options.label) ? column.options.label : [column.options.label];
					for (var labelIdx = 0; labelIdx < collabels.length; labelIdx++) {
						if (!colnames.includes(collabels[labelIdx])) colnames.push(collabels[labelIdx]);
					}
					if (Array.isArray(column.options.order)) {
						for (var c = 0; c < column.options.order.length; c++) {
							if (!colnames.includes(this.getOrderColumnName(column.options.order[c]))) {
								colnames.push(this.getOrderColumnName(column.options.order[c]));
							}
						}
					} else if (!this.isEmpty(column.options.order) && !colnames.includes(this.getOrderColumnName(column.options.order))) {
						colnames.push(this.getOrderColumnName(column.options.order));
					}
					
					// add table columns if it is a table
					if (column.type === 'table') {
						for (var t = 0; t < column.columns.length; t++) {
							if (column.columns[t].accessor !== 'id' && !colnames.includes(column.columns[t].accessor)) {
								colnames.push(column.columns[t].accessor);
							}
						}
					}

					// add search to array
					searches.push({
						Distinct: true,
						Columns: colnames,
						Entity: column.options.entity,
						Search: search,
						Order: (column.options.order == null || column.options.order === undefined || Array.isArray(column.options.order)) ? column.options.order : [ column.options.order ],
						PageNumber: -1,
						PageSize: -1
					});
					
					atLeastOneSearch = true;
				} else {
					// add static options to new state
					// if this is a dropdown
					if (column.type === 'dropdown') {
						// add a ref to each option so scrollIntoView will work
						var dropdownOptions = this.copyObject(column.options);
						for (var doIdx = 1; doIdx < dropdownOptions.length; doIdx++) {
							dropdownOptions[doIdx].ref = React.createRef();
						}
						this.setByAccessor(newState.options, column.accessor, dropdownOptions);
					} else {
						this.setByAccessor(newState.options, column.accessor, column.options);
					}
				}
			}
			
			// if nothing was added to searches array
			if (i === searches.length) {
				// add a null search so indeces will still line up with columns array
				searches.push(null);
			}
		}
		
		if (atLeastOneSearch) {
			// retrieve all searches from server
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/MultiSearch',
				data: {
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					searches: JSON.stringify(searches)
				}
			}).done(function (data) {
				// for each search result
				var allSucceeded = true;
				for (var i = 0; i < data.SearchResults.length; i++) {
					var result = data.SearchResults[i];

					if (result) {
						if (result.Success) {
							var column = columns[i];
							var options = (column.type === 'select' || column.type === 'table') ? [{ value: '', label: 'Select...' }] : [];
							var dictionary = {};
							var valueList = [];
							
							// for each option
							for (var o = 0; o < result.Data.length; o++) {
								// if we haven't already seen this value
								if (!valueList.includes(result.Data[o][column.options.value])) {
									// add to value list
									valueList.push(result.Data[o][column.options.value]);
									
									// if this isn't a deleted option in a table
									if (column.type !== 'table' || result.Data[o]['DELETE_FLAG'] !== 'Y') {
										// if this is a dropdown
										if (column.type === 'dropdown') {
											// add a ref to the option so we can scroll it into view
											options.push({
												value: result.Data[o][column.options.value],
												label: result.Data[o][column.options.label],
												ref: React.createRef()
											});
										} else if (column.type === 'multicolumn') {
											var labels = [];
											for (var labelIdx = 0; labelIdx < column.options.label.length; labelIdx++) {
												labels.push(result.Data[o][column.options.label[labelIdx]]);
											}
											options.push({
												value: result.Data[o][column.options.value],
												label: labels
											});
										} else {
											// add to options
											options.push({
												value: result.Data[o][column.options.value],
												label: result.Data[o][column.options.label]
											});
										}
									}
									
									// if table
									if (column.type === 'table') {
										// add to table dictionary
										dictionary[result.Data[o][column.options.value]] = result.Data[o];
									}
								}
							}

							// add options to new state
							self.setByAccessor(newState.options, column.accessor, options);
							
							// add table dictionary if table
							if (column.type === 'table') self.setByAccessor(newState.tableDictionary, column.accessor, dictionary);
						} else {
							self.showAlert('Server Error', data.Message);
							allSucceeded = false;
							break;
						}
					}
				}
				
				if (allSucceeded) {
					self.mergeStateWithArrays(newState);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			self.mergeStateWithArrays(newState);
		}			
	}
	
	lookUpOptionsOnChange(accessors, searchAccessors, newEditable) {		
		// make a new array for just the columns that need a new lookup
		var columns = [];
		
		// for each column
		for (var i = 0; i < this.props.columns.length; i++) {
			var column = this.props.columns[i];
						
			// if this is a select/list/dropdown/table column and has a search by this accessor
			if ((column.type === 'select' || column.type === 'list' || column.type === 'listoptions' || column.type === 'dropdown' ||
				(column.type === 'table' && column.editable !== false) || column.type === 'checklist' || column.type === 'multicolumn') && 
				(this.columnNeedsAccessor(column, accessors) || searchAccessors.includes(column.accessor))) {
				// add to list of columns that need a lookup
				columns.push(column);
			}
		}
		
		if (columns.length > 0) {
			var newState = {
				editable: newEditable,
				options: {},
				tableDictionary: {},
			};
			this.lookUpOptions(newState, columns);
			return true;
		} else {
			return false;
		}
	}

	columnNeedsAccessor(column, accessors) {
		// get a list of accessors used in this column's search
		var requiredAccessors = [];
		if (column.options.entity) {
			this.pushAllAccessors(column.accessor, requiredAccessors);
			if (column.options.search) {
				this.findRequiredAccessors(column.options.search, requiredAccessors);
			}
		}
		for (var i = 0; i < requiredAccessors.length; i++) {
			if (accessors.includes(requiredAccessors[i])) return true;
		}
		return false;
	}
	
	findRequiredAccessors(search, accessors) {
		if (search.Children) {
			for (var i = 0; i < search.Children.length; i++) {
				this.findRequiredAccessors(search.Children[i], accessors);
			}
		} else if (!this.isEmpty(search.StringAccessor)) {
			this.pushAllAccessors(search.StringAccessor, accessors);
		} else if (!this.isEmpty(search.LongAccessor)) {
			this.pushAllAccessors(search.LongAccessor, accessors);
		} else if (!this.isEmpty(search.IntAccessor)) {
			this.pushAllAccessors(search.IntAccessor, accessors);
		} else if (!this.isEmpty(search.DateAccessor)) {
			this.pushAllAccessors(search.DateAccessor, accessors);
		} else if (!this.isEmpty(search.DoubleAccessor)) {
			this.pushAllAccessors(search.DoubleAccessor, accessors);
		} else if (!this.isEmpty(search.DecimalAccessor)) {
			this.pushAllAccessors(search.DecimalAccessor, accessors);
		} else if (!this.isEmpty(search.LongListAccessor)) {
			this.pushAllAccessors(search.LongListAccessor, accessors);
		}
	}
	
	pushAllAccessors(accessor, accessors) {
		var lastDotIdx = accessor.lastIndexOf('.');
		while (lastDotIdx !== -1) {
			accessors.push(accessor);
			accessor = accessor.substring(0, lastDotIdx);
			lastDotIdx = accessor.lastIndexOf('.');
		}
		accessors.push(accessor);
	}
	
	componentDidUpdate(prevProps) {
		// calculate new value of editable
		var newEditable = this.isEmpty(this.props.editable) ? true : this.props.editable;
		var modified = this.getModifiedAccessors(prevProps.data, this.props.data);
		var modifiedSearch = [];
		
		// for each column in new props
		for (var newIdx = 0; newIdx < this.props.columns.length; newIdx++) {
			var newColumn = this.props.columns[newIdx];
			// find the column in the old props
			for (var oldIdx = 0; oldIdx < prevProps.columns.length; oldIdx++) {
				var oldColumn = prevProps.columns[oldIdx];
				if (newColumn.accessor === oldColumn.accessor &&
					((newColumn.options && newColumn.options.entity && newColumn.options.search &&
					  oldColumn.options && oldColumn.options.entity && oldColumn.options.search &&
					  !this.objectsAreEqual(newColumn.options.search, oldColumn.options.search)) ||
					 (newColumn.hidden !== oldColumn.hidden))) {
					modifiedSearch.push(newColumn.accessor);
				}
			}
		}
			
		var stateWasUpdated = false;
		if (modified.length > 0 || modifiedSearch.length > 0) {
			stateWasUpdated = this.lookUpOptionsOnChange(modified, modifiedSearch, newEditable);
		}
		if ((modified.length > 0 || modifiedSearch.length > 0 || newEditable !== this.state.editable) && !stateWasUpdated) {
			// This may cause infinite recursion... where? why? What happens if this is left out?
			this.mergeState({
				editable: newEditable,
				renderKey: this.state.renderKey + 1
			});
		}
	}		
	
	findColumn(columns, r, c) {
		for (var i = 0; i < columns.length; i++) {
			if (columns[i].row === r && columns[i].col === c) return columns[i];
		}

		return null;
	}
	
	getDropdownLabel(data, column) {
		var label = this.getByAccessor(data, column.accessor);
		if (!this.isEmpty(label) || !column.valueAccessor) return label;
		
		// search for the label in the list of options
		var value = this.getByAccessor(data, column.valueAccessor);
		for (var i = 0; i < column.options.length; i++) {
			if (value === column.options[i].value) return column.options[i].label;
		}
		return '';
	}
	
	getDropdownValue(data, column) {
		var value = null;
		
		// first try to look up the selected value
		if (column.valueAccessor) value = this.getByAccessor(data, column.valueAccessor);
		if (!this.isEmpty(value)) return value;
		
		// if there is no active label either, then nothing should be active
		var label = this.getByAccessor(data, column.accessor);
		if (this.isEmpty(label)) return null;
		
		// search for the shortest label that starts with what has been typed			
		var shortestMatchIdx = -1;
		var shortestMatchLength = -1;
		for (var i = 0; i < column.options.length; i++) {
			if (!this.isEmpty(column.options[i].label) && this.startsWithIgnoreCase(column.options[i].label, label)) {
				if (shortestMatchIdx === -1 || column.options[i].label.length < shortestMatchLength) {
					shortestMatchIdx = i;
					shortestMatchLength = column.options[i].label.length;
				}
			}
		}
		return shortestMatchIdx === -1 ? null : column.options[shortestMatchIdx].value;
	}

	getDropdownRef(data, column) {
		var value = null;
		var i;
		
		// first try to look up the selected value
		if (column.valueAccessor) value = this.getByAccessor(data, column.valueAccessor);
		if (!this.isEmpty(value)) {
			for (i = 0; i < column.options.length; i++) {
				if (column.options[i].value === value) return column.options[i].ref;
			}
		}
		
		// if there is no active label either, then nothing should be active
		var label = this.getByAccessor(data, column.accessor);
		if (this.isEmpty(label)) return null;
		
		// search for the shortest label that starts with what has been typed			
		var shortestMatchIdx = -1;
		var shortestMatchLength = -1;
		for (i = 0; i < column.options.length; i++) {
			if (!this.isEmpty(column.options[i].label) && this.startsWithIgnoreCase(column.options[i].label, label)) {
				if (shortestMatchIdx === -1 || column.options[i].label.length < shortestMatchLength) {
					shortestMatchIdx = i;
					shortestMatchLength = column.options[i].label.length;
				}
			}
		}
		
		var stateOptions = this.getByAccessor(this.state.options, column.accessor);
		return shortestMatchIdx === -1 ? null : stateOptions[shortestMatchIdx].ref;
	}

	onDropdownChange(column, option, openDropdown, callback) {
		// if this column has a valueAccessor
		if (column.valueAccessor) {
			// create array of accessors
			var accessors = [column.valueAccessor, column.accessor];
			
			// use supplied value or look it up in options
			var value = null;
			if (option.value) {
				value = option.value;
			} else {
				for (var i = 0; i < column.options.length; i++) {
					if (column.options[i].label === option.label) {
						value = column.options[i].value;
						break;
					}
				}
			}
			
			// create value/label array
			var valueLabel = [value, option.label];
			
			// fire event with arrays instead of single values
			this.props.onChange(accessors, valueLabel);
		} else {
			// single value only
			this.props.onChange(column.accessor, option.label);
		}
		
		// if the dropdown was requested
		var self = this;
		if (openDropdown) {
			var showDropdown = this.copyObject(this.state.showDropdown);
			this.setByAccessor(showDropdown, column.accessor, true);
			this.mergeState({ showDropdown: showDropdown }, () => {
				var activeRef = self.getDropdownRef(self.props.data, column);
				if (activeRef) activeRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
				if (callback) callback();
			});
		} else {
			if (callback) callback();
		}
	}
	
	onDropdownKeyDown(event, column) {
		var self = this;
		var showDropdown;
		var activeRef;
		var label;
		if (event.keyCode === 13) {
			if (this.props.autoSubmit) {
				event.preventDefault();
				// if something is highlighted in the dropdown, select it first and then submit
				activeRef = this.getDropdownRef(this.props.data, column);
				if (activeRef && this.getByAccessor(this.state.showDropdown, column.accessor) === true) {
					label = activeRef.current.innerText;
					showDropdown = this.copyObject(this.state.showDropdown);
					this.setByAccessor(showDropdown, column.accessor, false);
					this.mergeState({ showDropdown: showDropdown }, () => {
						self.onDropdownChange(column, { value: null, label: label }, false, () => {
							self.onSubmit(event);
						});
					});
				} else {
					this.onSubmit(event);
				}					
			} else {
				// return key - user wants to select what is highlighted in the dropdown.
				activeRef = this.getDropdownRef(this.props.data, column);
				if (activeRef) {
					if (this.getByAccessor(this.state.showDropdown, column.accessor) === true) {
						event.preventDefault();
						label = activeRef.current.innerText;
						showDropdown = this.copyObject(this.state.showDropdown);
						this.setByAccessor(showDropdown, column.accessor, false);
						this.mergeState({ showDropdown: showDropdown }, () => {
							this.onDropdownChange(column, { value: null, label: label }, false);
						});
					} else {
						this.onSubmit(event);
					}
				} else {
					if (this.getByAccessor(this.state.showDropdown, column.accessor) === false) {
						this.onSubmit(event);
					} else {
						event.preventDefault();
					}
				}
			}
		} else if (event.keyCode === 9) {
			showDropdown = this.copyObject(this.state.showDropdown);
			this.setByAccessor(showDropdown, column.accessor, false);
			this.mergeState({ showDropdown: showDropdown });
		}
	}
	
	onSelectChange(column, value) {
		// if this column has a labelAccessor
		if (column.labelAccessor) {
			// create array of accessors
			var accessors = [column.accessor, column.labelAccessor];
			
			// find label for value
			var label = null;
			for (var i = 0; i < column.options.length; i++) {
				// if the value is a match
				if ('' + column.options[i].value === '' + value) {
					// get the label
					label = column.options[i].label;
					break;
				}
			}
			
			// create value/label array
			var valueLabel = [value, label];
			
			// fire event with arrays instead of single values
			this.props.onChange(accessors, valueLabel);
		} else {
			// single value only
			this.props.onChange(column.accessor, value);
		}
	}
	
	onKeyDown(event, accessor) {
		if (event.keyCode === 13 && this.props.autoSubmit) this.onSubmit(event);
	}

	onSubmit(event) {
		event.preventDefault();

		var missingColumns;
		if (this.props.validate) {
			missingColumns = this.props.validate(event, this.props.columns, this.props.data);
		} else {
			missingColumns = this.validate(this.props.columns, this.props.data);
		}
		
		if (missingColumns) {
			this.showAlert("Required Fields", missingColumns);
		} else {
			// invoke provided onSubmit callback
			if (this.props.onSubmit) this.props.onSubmit(event);
		}
	}
	
	constructTableColumns(column) {		
		var columns = column.editable === false ? [
			{ Header: '', accessor: 'id', show: false }
		] : [
			{ Header: '', accessor: 'id', Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => {
				// create new value array
				var values = this.copyObject(this.getByAccessor(this.props.data, column.accessor));
				
				// remove id to be deleted
				values.splice(props.value, 1);
				
				// invoke onChange event
				this.props.onChange(column.accessor, values);
			}} />, width: 25 }
		];
		
		// add columns specified by caller    
		for (var i = 0; i < column.columns.length; i++) {
			columns.push(column.columns[i]);
		}
		
		return columns;
	}
	
	constructTableData(accessor) {
		var ids = this.getByAccessor(this.props.data, accessor);
		var tableData = [];
		if (Array.isArray(ids) && ids.length > 0) {
			var tableDictionary = this.getByAccessor(this.state.tableDictionary, accessor);
			if (tableDictionary) {
				for (var i = 0; i < ids.length; i++) {
					tableData.push(this.copyObject(tableDictionary[ids[i]]));
					// add id so all rows will display
					tableData[i].id = i;
				}
			}
		}
		return tableData;
	}
	
	renderColPad() {
		if (this.state.colPad > 0) return (<Col xs={this.state.colPad} />);
	}
	
	renderOptionalSubscript(column, i) {
		if (i === undefined || i === null) {
			if (column.subscript) {
				return (<>
					<span dangerouslySetInnerHTML={{__html: column.subscript}}></span>
				</>);
			}
		} else {
			if (column.subscripts) {
				return (<>
					<span dangerouslySetInnerHTML={{__html: column.subscripts[i]}}></span>
				</>);
			}
		}
	}
	
	doNotRender() {
		"Return this from your 'Cell' function if you do not want to render the row at all";
	}
	
	renderFromCellDefinition() {
		"Return this from your 'Cell' function if you want CellEdit to render it as if there were no 'Cell' function";
	}
	
	render() {
		if (this.state.expanded) {
			// locate the column that is expanded
			var colIdx = this.findColumnByAccessor(this.props.columns, this.state.expanded);
			var column = this.props.columns[colIdx];
			
			return (
				<Form onSubmit={(event) => event.preventDefault()}>
					<Container fluid>
						<Form.Group as={Row} controlId={column.accessor}>
							<Col sm={12} style={{ marginBottom: '14px' }}>
							  <Form.Label dangerouslySetInnerHTML={{__html: column.Header}}></Form.Label><Button style={{ marginTop: '-8px', marginLeft: '10px' }} onClick={(event) => this.mergeState({ expanded: null })}><FullscreenExit /></Button>
							  <InputGroup>
								<Form.Control as="textarea" rows={20} value={this.getByAccessor(this.props.data, column.accessor)}
									onChange={(event) => this.props.onChange(column.accessor, event.target.value)}
								/>
							  </InputGroup>{this.renderOptionalSubscript(column)}
							</Col>
						</Form.Group>
					</Container>
				</Form>
			);
		} else {
		
			// make a copy of columns
			var columns = JSON.parse(JSON.stringify(this.props.columns));
			
			// for each column
			for (var i = 0; i < columns.length; i++) {
				// if screen is locked then every column is locked
				if (!this.state.editable) columns[i].editable = false;
				
				// if we are not using a grid
				if (!this.state.useGrid) {
					// assign cells to single column
					columns[i].row = i;
					columns[i].col = 0;
				}
				
				// if this is a select/list/dropdown/table column
				if (columns[i].type === 'select' || columns[i].type === 'list' || columns[i].type === 'listoptions' || columns[i].type === 'dropdown' ||
					(columns[i].type === 'table' && columns[i].editable !== false) || columns[i].type === 'checklist' || columns[i].type === 'multicolumn') {
					// replace options with the list we looked up
					columns[i].options = this.getByAccessor(this.state.options, columns[i].accessor) || [];
				}
				
				// if this column has a cell/editor or onChange function, copy it over
				if (this.props.columns[i].Cell) columns[i].Cell = this.props.columns[i].Cell;
				if (this.props.columns[i].Editor) columns[i].Editor = this.props.columns[i].Editor;
				if (this.props.columns[i].onChange) columns[i].onChange = this.props.columns[i].onChange;
			}
			
			// construct rows and columns
			var rows = [];
			for (var r = 0; r < this.state.nrows; r++) {
				var cols = [];
				for (var c = 0; c < this.state.ncols; c++) {
					cols.push(this.findColumn(columns, r, c));
				}
				rows.push(cols);
			}

			return (
				<Form onSubmit={(event) => this.onSubmit(event)}>
					<Container fluid>
						{rows.map((cols, rowIndex) => {
							var rowStyle = {
								paddingTop: 5, 
								marginBottom: -10
							};
							if (!this.props.hideLines) rowStyle.borderTop = '1px solid #eee';
							return (<Row key={rowIndex} style={rowStyle}> {this.renderColPad()}
								{cols.map((column, colIndex) => {
								  if (column == null || column.hidden === true) {
									// return empty cell
									return <Col xs={this.state.colWidth} key={colIndex} />;
								  } else { 
									// if there is an editor function
									if (column.Editor !== undefined && column.Editor !== null) {
										// column wants to draw itself in its entirety
										var propsForEditor = {
											parent: this.props.parent,
											editor: this,
											labelColWidth: this.state.labelColWidth,
											valueColWidth: this.state.valueColWidth,
											column: column
										};
										if (column.accessors && Array.isArray(column.accessors)) {
											propsForEditor.values = [];
											for (var vfe = 0; vfe < column.accessors.length; vfe++) {
												propsForEditor.values.push(this.getByAccessor(this.props.data, column.accessors[i]));
											}
										} else if (!this.isEmpty(column.accessor)) {
											propsForEditor.value = this.getByAccessor(this.props.data, column.accessor);
										}
										return (
										  <Col xs={this.state.colWidth} key={colIndex}>
											{column.Editor(propsForEditor)}
										  </Col>
										);
									}
										
									var header = column.Header;
									if (column.required) header += ' <span style="color: red">*</span>';
									// if there is a cell function
									if (column.Cell !== undefined && column.Cell !== null) {
										// invoke cell function
										var customCellRender = column.Cell({ parent: this.props.parent, editor: this, value: this.getByAccessor(this.props.data, column.accessor) });
										
										// if the cell function has actually rendered something
										if (customCellRender !== this.renderFromCellDefinition()) {
											if (customCellRender === this.doNotRender()) return null;
											return (
											  <Col xs={this.state.colWidth} key={colIndex}>
												<Form.Group as={Row}>
													<Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
													<Col sm={this.state.valueColWidth} className='mt-2' style={{ marginBottom: '14px' }}>{customCellRender}{this.renderOptionalSubscript(column)}</Col>
												</Form.Group>
											  </Col>
											);
										}
									}
									
									// create optional disabled prop
									var addlInputProps = {};
									if (column.editable === false) addlInputProps.disabled = true;
									if (column.autofocus === true) addlInputProps.autoFocus = true;
									if (column.autocomplete === false) addlInputProps.autoComplete = 'off';
										
									if (column.type === 'checkbox') {
										return (
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor}>
												<Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
												<Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
												  {column.accessors ?
													<Container fluid>
													  <Row>
														{column.accessors.map((accessor, accessorIdx) => {
														  return(<Col key={accessorIdx}>
															  <InputGroup>
																<Form.Check style={{ paddingTop: '8px' }} type="checkbox" {...addlInputProps}
																	checked={this.getByAccessor(this.props.data, accessor)} label={column.label}
																	onClick={(event) => this.props.onChange(accessor, event.target.checked)}
																	onKeyDown={(event) => this.onKeyDown(event, accessor)} />
															  </InputGroup>{this.renderOptionalSubscript(column, accessorIdx)}
														  </Col>);
														})}
													  </Row>
													</Container>:
													<>
													  <InputGroup>
														<Form.Check style={{ paddingTop: '8px' }} type="checkbox" checked={this.getByAccessor(this.props.data, column.accessor)} label={column.label}
															onClick={(event) => this.props.onChange(column.accessor, event.target.checked)} 
															onKeyDown={(event) => this.onKeyDown(event, column.accessor)} {...addlInputProps} />
													  </InputGroup>{this.renderOptionalSubscript(column)}
													</>
												  }	
												</Col>
											</Form.Group>
										  </Col>
										);
									} else if (column.type === 'checklist') {
										let values = this.getByAccessor(this.props.data, column.accessor);
										return (<Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor}>
												<Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
												<Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
													<Container fluid>
														{column.options.map((option, optionIdx) => {
															return (<Row key={optionIdx}>
																<Col>
																	<InputGroup>
																		<Form.Check type="checkbox" {...addlInputProps}
																			checked={values.includes(option.value)} label={option.label}
																			onClick={(event) => {
																				var clickValues = this.getByAccessor(this.props.data, column.accessor);
																				var hasValue = clickValues.includes(option.value);
																				if (!hasValue && event.target.checked) {
																					// value was added
																					clickValues.push(option.value);
																				} else if (hasValue && !event.target.checked) {
																					// value was removed
																					clickValues.splice(clickValues.indexOf(option.value), 1);
																				}
																				this.props.onChange(column.accessor, clickValues);
																			}}
																			onKeyDown={(event) => this.onKeyDown(event, column.accessor)} />
																	</InputGroup>{this.renderOptionalSubscript(column)}
																</Col>
															</Row>);
														})}
													</Container>
												</Col>
											</Form.Group>
										</Col>);
									} else if (column.type === 'date') {
										return (	
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor}>
											  <Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
											  <Col sm={this.state.valueColWidth} style={{ paddingTop: '4px', marginBottom: '14px' }}>
												<InputGroup>
												  <DatePicker
													{...addlInputProps}
													selected={this.getDate(this.getByAccessor(this.props.data, column.accessor))}
													onChange={(date) => this.props.onChange(column.accessor, date === null ? null : this.dateTimeToMVC(date))}
													dateFormat='MM/dd/yy'
													onKeyDown={(event) => this.onKeyDown(event, column.accessor)}
												  />
												</InputGroup>{this.renderOptionalSubscript(column)}
											  </Col>
											</Form.Group>
										  </Col>
										);
									} else if (column.type === 'datetime') {
										return (	
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor}>
											  <Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
											  <Col sm={this.state.valueColWidth} style={{ paddingTop: '4px', marginBottom: '14px' }}>
												<InputGroup>
												  <DatePicker
													{...addlInputProps}
													selected={this.getDate(this.getByAccessor(this.props.data, column.accessor))}
													onChange={(date) => this.props.onChange(column.accessor, date === null ? null : this.dateTimeToMVC(date))}
													showTimeSelect
													dateFormat="Pp"
													onKeyDown={(event) => this.onKeyDown(event, column.accessor)}
												  />
												</InputGroup>{this.renderOptionalSubscript(column)}
											  </Col>
											</Form.Group>
										  </Col>
										);
									} else if (column.type === 'dropdown') {
										var self = this;
										var activeValue = this.getDropdownValue(this.props.data, column);
										// if there is no active value, then we pretend we have already seen it so that nothing will get marked as active
										var activeValueSeen = this.isEmpty(activeValue);
										return (
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor}>
											  <Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
											  <Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
												<InputGroup>
												  <Form.Control type="text" value={this.getDropdownLabel(this.props.data, column)} {...addlInputProps}
													onChange={(event) => this.onDropdownChange(column, { value: null, label: event.target.value }, true)}
													onKeyDown={(event) => this.onDropdownKeyDown(event, column)} />
												  {column.editable !== false && <DropdownButton title='' align='end' show={this.getByAccessor(this.state.showDropdown, column.accessor)}
													onToggle={(show) => {
														var showDropdown = this.copyObject(this.state.showDropdown);
														this.setByAccessor(showDropdown, column.accessor, show);
														this.mergeState({ showDropdown: showDropdown });
												  }}>
													{column.options.map((option, optionIdx) => {
													  if (!activeValueSeen && option.value === activeValue) {
														activeValueSeen = true;
														return (<Dropdown.Item key={optionIdx} ref={option.ref} active={true}
															onClick={(event) => self.onDropdownChange(column, option, false)} >{option.label}</Dropdown.Item>);
													  } else {
														return (<Dropdown.Item key={optionIdx} ref={option.ref} active={false}
															onClick={(event) => self.onDropdownChange(column, option, false)}>{option.label}</Dropdown.Item>);
													  }
													})}
												  </DropdownButton>}
												</InputGroup>{this.renderOptionalSubscript(column)}
											  </Col>
											</Form.Group>
										  </Col>
										);
									} else if (column.type === 'file') {
										if (column.editable === false) {
											return (
											  <Col xs={this.state.colWidth} key={colIndex}>
												<Form.Group as={Row}>
													<Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
													<Col sm={this.state.valueColWidth} className='mt-2' style={{ marginBottom: '14px' }}>
														<SlashCircle />
														{this.renderOptionalSubscript(column)}
													</Col>
												</Form.Group>
											  </Col>
											);
										} else {												
											var fileInputId = column.accessor + '_file';
											return (
											  <Col xs={this.state.colWidth} key={colIndex}>
												<Form.Group as={Row} controlId={column.accessor}>
												  <Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
												  <Col sm={this.state.valueColWidth} style={{ paddingTop: '4px', marginBottom: '14px' }}>
													<input name={fileInputId} id={fileInputId} type="file" accept={column.accept} size="30" {...addlInputProps}
														onChange={(event) => this.props.onChange(column.accessor, event.target.files[0])}
														onKeyDown={(event) => this.onKeyDown(event, column.accessor)}
													/>{this.renderOptionalSubscript(column)}
												  </Col>
												</Form.Group>
											  </Col>
											);
										}
									} else if (column.type === 'list') {
										return (
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor}>
											  <Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
											  <Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
												<InputGroup>
												  <Form.Control as="select" multiple value={this.getByAccessor(this.props.data, column.accessor)}
													  onKeyDown={(event) => this.onKeyDown(event, column.accessor)} {...addlInputProps}
													  onChange={(event) => this.props.onChange(column.accessor, [].slice.call(event.target.selectedOptions).map(item => item.attributes.value.value))}>
													  {column.options.map((option, optionIdx) => {
														  return (<option key={optionIdx} value={(option.value === null || option.value === undefined) ? '' : option.value}>{option.label}</option>);
													  })}
												  </Form.Control>
												</InputGroup>{this.renderOptionalSubscript(column)}
											  </Col>
											</Form.Group>
										  </Col>
										);
									} else if (column.type === 'listoptions') {
										return (
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row}>
												<Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
												<Col sm={this.state.valueColWidth} className='mt-2' style={{ marginBottom: '14px' }}>
													{column.options.map((option, optionIdx) => {
														return (<React.Fragment key={optionIdx}>
															{option.label}<br />
														</React.Fragment>);
													})}
													{this.renderOptionalSubscript(column)}
												</Col>
											</Form.Group>
										  </Col>
										);
									} else if (column.type === 'multicolumn') {
										if (column.editable === false) {
											return (
											  <Col xs={this.state.colWidth} key={colIndex}>
												<Form.Group as={Row}>
													<Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
													<Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
													  <InputGroup>
														<Form.Control type="text" value={this.getByAccessor(this.props.data, column.labelAccessor[0])} disabled />
													  </InputGroup>{this.renderOptionalSubscript(column)}
													</Col>
												</Form.Group>
											  </Col>
											);
										} else {
											var defaultLabels = [];
											for (var labelIdx = 0; labelIdx < column.labelAccessor.length; labelIdx++) {
												defaultLabels.push(this.getByAccessor(this.props.data, column.labelAccessor[labelIdx]));
											}
											var defaultOption = {
												value: this.getByAccessor(this.props.data, column.accessor),
												label: defaultLabels
											};
											return (
											  <Col xs={this.state.colWidth} key={colIndex}>
												<Form.Group as={Row} controlId={column.accessor}>
												  <Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
												  <Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
													<InputGroup>
														<MultiColumn defaultOption={defaultOption} options={column.options} compare={column.compare}
														  onChange={(value, labels) => {
															var accessors = [column.accessor];
															for (var accessorIdx = 0; accessorIdx < column.labelAccessor.length; accessorIdx++) {
																accessors.push(column.labelAccessor[accessorIdx]);
															}
															var values = [value];
															for (var valueIdx = 0; valueIdx < labels.length; valueIdx++) {
																values.push(labels[valueIdx]);
															}
															this.props.onChange(accessors, values);
														  }}
														/>
													</InputGroup>{this.renderOptionalSubscript(column)}
												  </Col>
												</Form.Group>
											  </Col>
											);
										}
									} else if (column.type === 'numpad') {
										return (
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor}>
											  <Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
											  <Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
												<InputGroup>
												  <NumPad value={this.getByAccessor(this.props.data, column.accessor)} onChange={(value) => this.props.onChange(column.accessor, value)} />
												</InputGroup>{this.renderOptionalSubscript(column)}
											  </Col>
											</Form.Group>
										  </Col>
										);										
									} else if (column.type === 'select') {
										return (
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor}>
											  <Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
											  <Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
												<InputGroup>
												  <Form.Control as="select" value={this.getByAccessorForInput(this.props.data, column.accessor)}
													  onChange={(event) => this.onSelectChange(column, event.target.value)}
													  onKeyDown={(event) => this.onKeyDown(event, column.accessor)} {...addlInputProps}>
													  {column.options.map((option, optionIdx) => {
														  return (<option key={optionIdx} value={option.value}>{option.label}</option>);
													  })}
												  </Form.Control>
												</InputGroup>{this.renderOptionalSubscript(column)}
											  </Col>
											</Form.Group>
										  </Col>
										);
									} else if (column.type === 'table') {
										if (column.editable === false) {
											return (
											  <Col xs={this.state.colWidth} key={colIndex}>
												<Form.Group as={Row}>
													<Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
													<Col sm={this.state.valueColWidth} className='mt-2' style={{ marginBottom: '14px' }}>
													  <ChemTable parent={this} name={column.name} columns={this.constructTableColumns(column)} 
														data={this.constructTableData(column.accessor)} />
													{this.renderOptionalSubscript(column)}</Col>
												</Form.Group>
											  </Col>
											);	
										} else {												
											return (
											  <Col xs={this.state.colWidth} key={colIndex}>
												<Form.Group as={Row} controlId={column.accessor}>
												  <Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
												  <Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
													<InputGroup>
														<Container>
														  <Row>
															<Col sm={9} style={{ marginTop: '0px' }}>
															  <Form.Control as="select" defaultValue={this.getByAccessor(this.state.tableSelect, column.accessor)}
																onChange={(event) => this.setByAccessor(this.state.tableSelect, column.accessor, event.target.value)}
																onKeyDown={(event) => this.onKeyDown(event)}>
																  {column.options.map((option, optionIdx) => {
																	  return (<option key={optionIdx} value={option.value}>{option.label}</option>);
																  })}
															  </Form.Control>
															</Col>
															<Col sm={3} style={{ marginTop: '0px' }}>
															  <Button variant='warning' onClick={(event) => {
																var addValue = this.getByAccessor(this.state.tableSelect, column.accessor);
																if (addValue) {
																  this.props.onChange(column.accessor, 
																	this.copyArrays(this.getByAccessor(this.props.data, column.accessor), 
																	  [addValue]))
																}}}>Add</Button>
															</Col>
														  </Row>
														  <Row>
															<Col sm={12} style={{ marginTop: '8px' }}>
															  <ChemTable parent={this} name={column.name} columns={this.constructTableColumns(column)} 
																data={this.constructTableData(column.accessor)} />
															</Col>
														  </Row>
														</Container>
													</InputGroup>{this.renderOptionalSubscript(column)}
												  </Col>
												</Form.Group>
											  </Col>
											);
										}
									} else if (column.type === 'textarea') {
										return (
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor}>
												<Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
												<Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
												  <InputGroup>
													<Form.Control as="textarea" rows={column.rows || 3} value={this.getByAccessorForInput(this.props.data, column.accessor)}
														onChange={(event) => this.props.onChange(column.accessor, event.target.value)} {...addlInputProps}
													/>{column.expand && <Button onClick={(event) => this.mergeState({ expanded: column.accessor })}><ArrowsFullscreen /></Button>}
												  </InputGroup>{this.renderOptionalSubscript(column)}
												</Col>
											</Form.Group>
										  </Col>
										);
									} else if (column.type === 'time') {
										return (
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor}>
											  <Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
											  <Col sm={this.state.valueColWidth} style={{ paddingTop: '4px', marginBottom: '14px' }}>
												<InputGroup>
												  <DatePicker
													{...addlInputProps}
													selected={this.getDate(this.getByAccessor(this.props.data, column.accessor))}
													onChange={(date) => this.props.onChange(column.accessor, date === null ? null : this.dateTimeToMVC(date))}
													showTimeSelect
													showTimeSelectOnly
													timeIntervals={15}
													timeCaption="Time"
													dateFormat="h:mm aa"
													onKeyDown={(event) => this.onKeyDown(event)}
												  />
												</InputGroup>{this.renderOptionalSubscript(column)}
											  </Col>
											</Form.Group>
										  </Col>
										);
									} else {
										/* if we are going to display one non-editable div, the row height needs to be 100% */
										var optionalRowHeight = {};
										if (column.accessor && column.editable === false) optionalRowHeight.height = '100%';
										return (
										  <Col xs={this.state.colWidth} key={colIndex}>
											<Form.Group as={Row} controlId={column.accessor} style={optionalRowHeight}>
												<Form.Label column sm={this.state.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: header}}></Form.Label>
												{column.accessor && column.editable === false ?
													<Col sm={this.state.valueColWidth} style={{ flex: 1, display: 'flex', alignItems: 'center', marginBottom: '14px', background: '#e9ecef', borderRadius: 5 }}>
														<div>{this.getByAccessorForInput(this.props.data, column.accessor)}</div>
													</Col> :
													<Col sm={this.state.valueColWidth} style={{ marginBottom: '14px' }}>
													  {column.accessors ?
														<Container fluid><Row>
														{column.accessors.map((accessor, accessorIdx) => {
															return(<Col key={accessorIdx}>
																<InputGroup>
																	<Form.Control type="text" value={this.getByAccessorForInput(this.props.data, accessor)}
																		onChange={(event) => this.props.onChange(accessor, event.target.value)}
																		onKeyDown={(event) => this.onKeyDown(event, accessor)} {...addlInputProps} />
																</InputGroup>{this.renderOptionalSubscript(column, accessorIdx)}
															</Col>);
														})}
														</Row></Container>:
														<><InputGroup>
															<Form.Control type="text" value={this.getByAccessorForInput(this.props.data, column.accessor)}
																onChange={(event) => this.props.onChange(column.accessor, event.target.value)}
																onKeyDown={(event) => this.onKeyDown(event, column.accessor)} {...addlInputProps} />
														</InputGroup>{this.renderOptionalSubscript(column)}</>
													  }
													</Col>
												}
											</Form.Group>
										  </Col>
										);
									}
								  }
								})}
							</Row>);
						})}
						{this.props.children}
					</Container>
				</Form>		
			);
		}
	}
}

export default ChemEdit;