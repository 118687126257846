import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Speedtypes extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			SPEEDTYPE: '',
			RCH_ACCT: '',
			FUND: '',
			DEPT_ID: '',
			PROGRAM: '',
			CF1: '',
			CF2: '',
			RA: null,
			PI_ROLE_ID: null,
			PI_TYPE: null,
			FUND_NAME: '',
			AWARD_NUMBER: '',
			FUND_ENDING: null,
			DATE_SEARCH_TYPE: '=',
			FABRICATION_FLAG: 'All',
			CAP_PROJECT_FLAG: 'All',
			OUTSIDE_FLAG: 'All',
			SUBSIDY_FLAG: 'All',
			SPONSORED_FLAG: 'All',
			ACTIVE_FLAG: 'Yes'
		};
		
		// initialize speedtype list
		var initialSpeedtypeList = this.copySpeedtypeList();
		initialSpeedtypeList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			speedtypeList: initialSpeedtypeList,
			search: search,
			searchCount: null
		};
	}
	
	copySpeedtypeList() {
		var speedtypeListCopy = this.copyObject(speedtypeList);
				
		for (var i = 0; i < speedtypeList.columns.length; i++) {
			if (speedtypeList.columns[i].Header) {
				speedtypeListCopy.columns[i].Header = speedtypeList.columns[i].Header;
			}
			if (speedtypeList.columns[i].Cell) {
				speedtypeListCopy.columns[i].Cell = speedtypeList.columns[i].Cell;
			}
		}
		
		// remove add/edit column if not authorized
		if (this.props.user.crs.userAccessID !== 1) {
			speedtypeListCopy.columns.splice(0, 1);
		}

		return speedtypeListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newSpeedtypeList = this.copySpeedtypeList();
		newSpeedtypeList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			speedtypeList: newSpeedtypeList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.SPEEDTYPE)) search.Children.push({ Attribute: 'SPEEDTYPE', Operator: 'like', StringValue: '%' + data.SPEEDTYPE + '%' });
		if (!this.isEmpty(data.RCH_ACCT)) search.Children.push({ Attribute: 'RCH_ACCT', Operator: '=', StringValue: data.RCH_ACCT });
		if (!this.isEmpty(data.FUND)) search.Children.push({ Attribute: 'FUND', Operator: '=', StringValue: data.FUND });
		if (!this.isEmpty(data.DEPT_ID)) search.Children.push({ Attribute: 'DEPT_ID', Operator: '=', StringValue: data.DEPT_ID });
		if (!this.isEmpty(data.PROGRAM)) search.Children.push({ Attribute: 'PROGRAM', Operator: '=', StringValue: data.PROGRAM });
		if (!this.isEmpty(data.CF1)) search.Children.push({ Attribute: 'CF1', Operator: '=', StringValue: data.CF1 });
		if (!this.isEmpty(data.CF2)) search.Children.push({ Attribute: 'CF2', Operator: '=', StringValue: data.CF2 });
		if (!this.isEmpty(data.RA)) search.Children.push({ Attribute: 'RA', Operator: '=', LongValue: data.RA });
		if (!this.isEmpty(data.PI_ROLE_ID)) search.Children.push({ Attribute: 'PI_ROLE_ID', Operator: '=', LongValue: data.PI_ROLE_ID });
		if (!this.isEmpty(data.PI_TYPE)) search.Children.push({ Attribute: 'PI_TYPE', Operator: '=', LongValue: data.PI_TYPE });
		if (!this.isEmpty(data.FUND_NAME)) search.Children.push({ Attribute: 'FUND_NAME', Operator: '=', StringValue: data.FUND_NAME });
		if (!this.isEmpty(data.AWARD_NUMBER)) search.Children.push({ Attribute: 'AWARD_NUMBER', Operator: '=', StringValue: data.AWARD_NUMBER });
		if (!this.isEmpty(data.FUND_ENDING)) search.Children.push({ Attribute: 'FUND_ENDING', Operator: data.DATE_SEARCH_TYPE, DateValue: this.dateTimeToMVC(this.getDate(data.FUND_ENDING)) });
		if (data.FABRICATION_FLAG === 'No' || data.FABRICATION_FLAG === 'Yes') {
			if (data.FABRICATION_FLAG === 'Yes') {
				search.Children.push({ Attribute: 'FABRICATION_FLAG', Operator: '=', StringValue: 'Y' });
			} else {
				search.Children.push({ Operator: 'or', Children: [
					{ Attribute: 'FABRICATION_FLAG', Operator: '!=', StringValue: 'Y' },
					{ Attribute: 'FABRICATION_FLAG', Operator: 'is null' } ] } ); 
			}
		}
		if (data.CAP_PROJECT_FLAG === 'No' || data.CAP_PROJECT_FLAG === 'Yes') {
			if (data.CAP_PROJECT_FLAG === 'Yes') {
				search.Children.push({ Attribute: 'CAP_PROJECT_FLAG', Operator: '=', StringValue: 'Y' });
			} else {
				search.Children.push({ Operator: 'or', Children: [
					{ Attribute: 'CAP_PROJECT_FLAG', Operator: '!=', StringValue: 'Y' },
					{ Attribute: 'CAP_PROJECT_FLAG', Operator: 'is null' } ] } ); 
			}
		}
		if (data.OUTSIDE_FLAG === 'No' || data.OUTSIDE_FLAG === 'Yes') {
			if (data.OUTSIDE_FLAG === 'Yes') {
				search.Children.push({ Attribute: 'JOURNAL_TYPE', Operator: 'in', StringList: ['O', 'U'] });
			} else {
				search.Children.push({ Operator: 'or', Children: [
					{ Attribute: 'JOURNAL_TYPE', Operator: '=', StringValue: 'E' },
					{ Attribute: 'JOURNAL_TYPE', Operator: 'is null' } ] } ); 
			}
		}
		if (data.SUBSIDY_FLAG === 'No' || data.SUBSIDY_FLAG === 'Yes') {
			if (data.SUBSIDY_FLAG === 'Yes') {
				search.Children.push({ Attribute: 'SUBSIDY_FLAG', Operator: '=', StringValue: 'Y' });
			} else {
				search.Children.push({ Operator: 'or', Children: [
					{ Attribute: 'SUBSIDY_FLAG', Operator: '!=', StringValue: 'Y' },
					{ Attribute: 'SUBSIDY_FLAG', Operator: 'is null' } ] } ); 
			}
		}
		if (data.SPONSORED_FLAG === 'No' || data.SPONSORED_FLAG === 'Yes') {
			if (data.SPONSORED_FLAG === 'Yes') {
				search.Children.push({ Attribute: 'SPONSORED_FLAG', Operator: '=', StringValue: 'Y' });
			} else {
				search.Children.push({ Operator: 'or', Children: [
					{ Attribute: 'SPONSORED_FLAG', Operator: '!=', StringValue: 'Y' },
					{ Attribute: 'SPONSORED_FLAG', Operator: 'is null' } ] } ); 
			}
		}
		if (data.ACTIVE_FLAG === 'No' || data.ACTIVE_FLAG === 'Yes') {
			if (data.ACTIVE_FLAG === 'Yes') {
				search.Children.push({ Operator: 'and', Children: [
					{ Attribute: 'END_VALID_DATE', Operator: 'is null' },
					{ Attribute: 'CHILD_ID', Operator: 'is null' } ] } ); 
			} else {
				search.Children.push({ Operator: 'or', Children: [
					{ Attribute: 'END_VALID_DATE', Operator: 'is not null' },
					{ Attribute: 'CHILD_ID', Operator: 'is not null' } ] } ); 
			}
		}

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addSpeedtype() {
		this.props.navigate('/CRS/Speedtypes/new');
	}
	
	editSpeedtype(speedtypeId) {
		this.props.navigate('/CRS/Speedtypes/' + speedtypeId);		
	}
	
	onTableSort(newOrder) {
		// make copy of current speedtype list
		var newSpeedtypeList = this.copyTable(this.state.speedtypeList);
		
		// clear current ordering
		newSpeedtypeList.query.order = newOrder;
		
		this.mergeState({
			speedtypeList: newSpeedtypeList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Speedtypes',
			columns: exportColumns,
			entity: 'RECHARGES.SPEEDTYPES_VW',
			search: this.constructSearch(this.state.search),
			order: 'SPEEDTYPE'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Speedtypes</span>
					<ChemEdit parent={this} columns={speedtypeSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_Speedtypes' table={this.state.speedtypeList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='420px' onTableSort={(newOrder) => this.onTableSort(newOrder)} />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const speedtypeSearch = [
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', row: 0, col: 0 },
	{ Header: 'Account Code', accessor: 'RCH_ACCT', row: 0, col: 1, subscript: '(recharge account)' },
	{ Header: 'Fund', accessor: 'FUND', row: 0, col: 2 },
	{ Header: 'Dept ID', accessor: 'DEPT_ID', row: 0, col: 3 },
	{ Header: 'Program', accessor: 'PROGRAM', row: 1, col: 0 },
	{ Header: 'CF1', accessor: 'CF1', row: 1, col: 1 },
	{ Header: 'CF2', accessor: 'CF2', row: 1, col: 2 },
	{ Header: 'RA', accessor: 'RA', row: 1, col: 3, type: 'select',
		options: { value: 'PERSON_ID', label: 'RA', entity: 'RA_SELECT_VW', order: 'RA' } },
	{ Header: 'PI Name', accessor: 'PI_ROLE_ID', row: 2, col: 0, type: 'select',
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'RECHARGES.PI_SELECT_VW', order: 'PI_NAME' } },
	{ Header: 'PI Type', accessor: 'PI_TYPE', row: 2, col: 1, type: 'select',
		options: { value: 'TYPE_ID', label: 'PI_TYPE', entity: 'RECHARGES.PI_TYPE_SELECT_VW', order: 'PI_TYPE' } },
	{ Header: 'Fund Name', accessor: 'FUND_NAME', row: 2, col: 2 },
	{ Header: 'Award Number', accessor: 'AWARD_NUMBER', row: 2, col: 3 },
	{ Header: 'Fund End Date', accessor: 'FUND_ENDING', row: 3, col: 0, type: 'date' },
	{ Header: 'Date Search Type', accessor: 'DATE_SEARCH_TYPE', row: 3, col: 1, type: 'select',
		options: [{ value: '=', label: '=' }, { value: '<=', label: '≤' }, { value: '>=', label: '≥' }] },
	{ Header: 'Fabrication', accessor: 'FABRICATION_FLAG', row: 3, col: 2, type: 'select',
		options: [{ value: 'All', label: 'All' }, { value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }] },
	{ Header: 'Capital Project', accessor: 'CAP_PROJECT_FLAG', row: 3, col: 3, type: 'select',
		options: [{ value: 'All', label: 'All' }, { value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }] },
	{ Header: 'Outside', accessor: 'OUTSIDE_FLAG', row: 4, col: 0, type: 'select',
		options: [{ value: 'All', label: 'All' }, { value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }] },
	{ Header: 'Subsidy', accessor: 'SUBSIDY_FLAG', row: 4, col: 1, type: 'select',
		options: [{ value: 'All', label: 'All' }, { value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }] },
	{ Header: 'Sponsored', accessor: 'SPONSORED_FLAG', row: 4, col: 2, type: 'select',
		options: [{ value: 'All', label: 'All' }, { value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }] },
	{ Header: 'Active', accessor: 'ACTIVE_FLAG', row: 4, col: 3, type: 'select',
		options: [{ value: 'All', label: 'All' }, { value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }] }
];

const speedtypeList = {
	query: { entity: 'RECHARGES.SPEEDTYPES_VW', search: null, order: 'SPEEDTYPE' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addSpeedtype()} />, accessor: 'id', sortable: false,
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editSpeedtype(props.row.values.SPEEDTYPE_ID)} />, width: 25 },
		{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 120 },
		{ Header: 'Fund', accessor: 'FUND', width: 70 },
		{ Header: 'Dept ID', accessor: 'DEPT_ID', width: 70 },
		{ Header: 'Prgm', accessor: 'PROGRAM', width: 50 },
		{ Header: 'CF1', accessor: 'CF1', width: 90 },
		{ Header: 'CF2', accessor: 'CF2', width: 90 },
		{ Header: 'Fund Name', accessor: 'FUND_NAME', width: 400 },
		{ Header: 'Award Num.', accessor: 'AWARD_NUMBER', width: 110 },
		{ Header: '30 days prior to Fund End', accessor: 'FUND_ENDING_30', width: 90, 
			Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: 'Fund End Date', accessor: 'FUND_ENDING', width: 90, 
			Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: 'Fund Extension Request Date', accessor: 'EXTENSION_REQ', width: 90, 
			Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: 'PI', accessor: 'PI', width: 200 },
		{ Header: 'RA', accessor: 'RA_NAME', width: 200 },
		{ Header: 'No Labor Subsidy', accessor: 'SUBSIDY_FLAG', width: 70 },
		{ Header: 'Fab.', accessor: 'FABRICATION_FLAG', width: 50 },
		{ Header: 'Cap. Proj.', accessor: 'CAP_PROJECT_FLAG', width: 50 },
		{ Header: 'Sponsored', accessor: 'SPONSORED_FLAG', width: 90 },
		{ Header: 'Status', accessor: 'STATUS', width: 140 },
		{ Header: 'SPEEDTYPE_ID', accessor: 'SPEEDTYPE_ID', show: false }
	]
};

const exportColumns = [
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 15 },
	{ Header: 'Fund', accessor: 'FUND', width: 8 },
	{ Header: 'Dept ID', accessor: 'DEPT_ID', width: 8 },
	{ Header: 'Prgm', accessor: 'PROGRAM', width: 6 },
	{ Header: 'CF1', accessor: 'CF1', width: 10 },
	{ Header: 'CF2', accessor: 'CF2', width: 10 },
	{ Header: 'Fund Name', accessor: 'FUND_NAME', width: 50 },
	{ Header: 'Award Num.', accessor: 'AWARD_NUMBER', width: 12 },
	{ Header: '30 days prior to Fund End', accessor: 'FUND_ENDING_30', width: 10, format: 'mm/dd/yy' },
	{ Header: 'Fund End Date', accessor: 'FUND_ENDING', width: 10, format: 'mm/dd/yy' },
	{ Header: 'Fund Extension Request Date', accessor: 'EXTENSION_REQ', width: 10, format: 'mm/dd/yy' },
	{ Header: 'PI', accessor: 'PI', width: 25 },
	{ Header: 'RA', accessor: 'RA_NAME', width: 25 },
	{ Header: 'No Labor Subsidy', accessor: 'SUBSIDY_FLAG', width: 8 },
	{ Header: 'Fab.', accessor: 'FABRICATION_FLAG', width: 6 },
	{ Header: 'Cap. Proj.', accessor: 'CAP_PROJECT_FLAG', width: 6 },
	{ Header: 'Sponsored', accessor: 'SPONSORED_FLAG', width: 10 },
	{ Header: 'Status', accessor: 'STATUS', width: 10 },
];

export default withRouter(Speedtypes);