import ChemComponent from '../ChemComponent';
import { withStudentDefaultRoute } from './withStudentDefaultRoute';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import EditTable from '../EditTable';
import Tabs from '../Tabs';
import HashLink from '../HashLink';
import { Col, Container, Row, Button, InputGroup, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { Calculator, PlusSquare, PencilSquare, LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Student extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			editable: props.editable,
			activeTab: props.params.activeTab || 'Student',
			application: {},
			editorsOpen: [],
			calculator: this.copyObject(emptyCalculator),
			appStatus: null,
			renderKey: 0,
			unsavedChanges: false,
			dissertationSearchByFirstName: false,
			thesisColumns: this.copyColumns(thesisColumns)
		}
	}

	componentDidMount() {
		this.loadStudent();
	}
	
	componentDidUpdate(prevProps) {
		var newActiveTab = this.props.params.activeTab || 'Student';
		
		// this is necessary so browser back/forward buttons will work across tabs
		if (newActiveTab !== this.state.activeTab) {
			this.mergeState({
				activeTab: newActiveTab,
				renderKey: this.state.renderKey + 1
			});
		}
	}

	loadStudent(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/GradOffice/GetStudent',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				studentid: this.props.params.studentId
			}
		}).done(function (data) {
			if (data.Success) {
				var i;
				var application = data.application;
				
				// convert dates, checkboxes - student CA_RESIDENT is custom
				var studentCAResident = application.students.CA_RESIDENT === 1;
				self.mvc2js(application, studentColumns);
				self.mvc2js(application, detailColumns);
				self.mvc2js(application, contactTempColumns);
				self.mvc2js(application, gpaColumns);
				self.mvc2js(application, qe1Columns);
				self.mvc2js(application, qe2Columns);
				self.mvc2js(application, thesisColumns);
				self.mvc2js(application.financial_notes, fnConvertColumns);
				
				// convert residency checkboxes
				if (application.CA_RESIDENT === 'Y') {
					application.CA_RESIDENT = 'RESIDENT';
				} else if (application.CA_RES_EXP === 'Y') {
					application.CA_RESIDENT = 'EXPECTED';
				} else {
					application.CA_RESIDENT = 'NON-RESIDENT';
				}
				application.students.CA_RESIDENT = studentCAResident;
				
				// convert previous submission info
				application.PREV_UCB_GRAD_APPL = application.app_details.PREV_UCB_GRAD_APPL_TERM + ' ' + application.app_details.PREV_UCB_GRAD_APPL_YR;
				
				// set Modified info
				application.MODIFIED = self.combineChangeInfo(
					self.composeChangeInfo(application.CREATED_BY, application.CREATED),
					self.composeChangeInfo(application.ENTERED_BY, application.LAST_MODIF));
				application.students.MODIFIED = self.composeChangeInfo(
					self.isEmpty(application.students.MODIF_BY) ? application.students.CREATED_BY : application.students.MODIF_BY,
					application.students.LAST_MODIF);
					
				// set modified and current year info
				for (i = 0; i < application.fellowships.length; i++) {
					application.fellowships[i].CREATED = self.composeChangeInfo(application.fellowships[i].CREATED_BY, application.fellowships[i].CREATE_DATE);
					application.fellowships[i].MODIFIED = self.composeChangeInfo(application.fellowships[i].MODIF_BY, application.fellowships[i].MODIF_DATE);
					application.fellowships[i].current_year = self.copyObject({
							YEAR: 'Current',
							FEES: 0,
							TUITION: 0,
							STIPEND: 0,
							TOTAL_AVAIL: 0,
							TOTAL: 0
						});
				}
				
				// fill out advisor arrays so they all have 3 entries
				self.prepRAPI(application.research_director, 1);
				self.prepRAPI(application.associate_advisor, 2);
				self.prepRAPI(application.financial_only_pi, 3);
				
				// generate string form of thesis date
				var dtThesisDate = self.getDate(application.students.THESIS_DATE);
				application.students.THESIS_DATE_MM_YY = dtThesisDate ?
					(('' + (dtThesisDate.getMonth() + 1)).padStart(2, '0') + '/' + dtThesisDate.getFullYear()) : '';

				self.mergeState({
					application: application,
					appStatus: data.status,
					renderKey: self.state.renderKey + 1,
					unsavedChanges: false
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	prepRAPI(rapi, piType) {
		while (rapi.length < 3) {
			rapi.push({
				PI_ASSIGN_ID: 'pi_assign_id_seq.nextval',
				STUDENT_ID: this.props.params.studentId || null,
				PERSON_ID: null,
				PI_NAME: '',
				PI_TYPE: piType
			});
		}
	}
	
	cullRAPI(rapi) {
		for (var i = 2; i >= 0; i--) {
			if (this.isEmpty(rapi[i].PERSON_ID) && this.isEmpty(rapi[i].PI_NAME)) {
				rapi.splice(i, 1);
			}
		}
	}

	onEdit(accessor, editorIsOpen) {
		this.mergeState({
			editorsOpen: this.setEditorStatus(accessor, editorIsOpen),
			renderKey: this.state.renderKey + 1
		});
	}
	
	setEditorStatus(accessor, editorIsOpen) {
		var editorsOpen = this.copyObject(this.state.editorsOpen);
		if (editorIsOpen) {
			// add to list if it isn't already present
			if (!editorsOpen.includes(accessor)) editorsOpen.push(accessor);
		} else {			
			// remove from list if it is present
			if (editorsOpen.includes(accessor)) editorsOpen.splice(editorsOpen.indexOf(accessor), 1);
		}
		return editorsOpen;
	}		

	onChange(accessor, value) {
		var application = this.copyObject(this.state.application);
		if (Array.isArray(accessor)) {
			for (var i = 0; i < accessor.length; i++) {
				this.setByAccessor(application, accessor[i], value[i]);
			}
		} else {
			this.setByAccessor(application, accessor, value);
		}
		var newState = { 
			application: application, 
			renderKey: this.state.renderKey + 1, 
			unsavedChanges: true
		};
		// if this accessor represents an EditTable
		if (['app_education'].includes(accessor)) {
			// update editor status as well
			newState.editorsOpen = this.setEditorStatus(accessor, false);
		}
		this.mergeState(newState);
	}
	
	onChangeDissertationSearch(dissertationSearchByFirstName) {
		// determine name of view to use
		var columnName = dissertationSearchByFirstName ? 'RES_DIRECTOR_FNAME' : 'RES_DIRECTOR_NAME';
		
		// make copy of thesis columns
		var newThesisColumns = this.copyColumns(thesisColumns);
		
		// modify thesis columns to use new res director column name
		this.setDissertationDropDownColumn(newThesisColumns, 'students.THESIS_CHAIR', columnName);
		this.setDissertationDropDownColumn(newThesisColumns, 'students.INSIDE_READER', columnName);
		this.setDissertationDropDownColumn(newThesisColumns, 'students.ADD_READER', columnName);
		this.setDissertationDropDownColumn(newThesisColumns, 'students.OUTSIDE_READER', columnName);
		
		// merge new values into state
		this.mergeState({
			dissertationSearchByFirstName: dissertationSearchByFirstName,
			thesisColumns: newThesisColumns
		});
	}
	
	setDissertationDropDownColumn(newThesisColumns, accessor, columnName) {
		// find the column we need to modify
		var colIdx = this.findColumnByAccessor(newThesisColumns, accessor);
		
		// if the column was found
		if (colIdx >= 0) {
			// change label and sorting
			newThesisColumns[colIdx].options.label = columnName;
			newThesisColumns[colIdx].options.order = ['SORT_ORDER',columnName];
		}
	}
	
	onChangeCalculator(accessor, value) {
		var calculator = this.copyObject(this.state.calculator);
		this.setByAccessor(calculator, accessor, value);
		
		// calculate GPA
		var AUnits = this.isNumeric(calculator.A) ? +calculator.A : 0;
		var AMinusUnits = this.isNumeric(calculator.AMinus) ? +calculator.AMinus : 0;
		var BPlusUnits = this.isNumeric(calculator.BPlus) ? +calculator.BPlus : 0;
		var BUnits = this.isNumeric(calculator.B) ? +calculator.B : 0;
		var BMinusUnits = this.isNumeric(calculator.BMinus) ? +calculator.BMinus : 0;
		var CPlusUnits = this.isNumeric(calculator.CPlus) ? +calculator.CPlus : 0;
		var CUnits = this.isNumeric(calculator.C) ? +calculator.C : 0;
		var CMinusUnits = this.isNumeric(calculator.CMinus) ? +calculator.CMinus : 0;
		var DPlusUnits = this.isNumeric(calculator.DPlus) ? +calculator.DPlus : 0;
		var DUnits = this.isNumeric(calculator.D) ? +calculator.D : 0;
		var DMinusUnits = this.isNumeric(calculator.DMinus) ? +calculator.DMinus : 0;
		var FUnits = this.isNumeric(calculator.F) ? +calculator.F : 0;
		var totalUnits = AUnits + AMinusUnits + 
			BPlusUnits + BUnits + BMinusUnits + 
			CPlusUnits + CUnits + CMinusUnits +
			DPlusUnits + DUnits + DMinusUnits + FUnits;
		var gpa = totalUnits === 0 ? 0 : ((AUnits * 4.0 + AMinusUnits * 3.7 +
			BPlusUnits * 3.3 + BUnits * 3.0 + BMinusUnits * 2.7 +
			CPlusUnits * 2.3 + CUnits * 2.0 + CMinusUnits * 1.7 +
			DPlusUnits * 1.3 + DUnits * 1.0 + DMinusUnits * 0.7)/totalUnits);
		var application = this.copyObject(this.state.application);
		this.setByAccessor(application, this.state.calculator.accessor, gpa.toLocaleString('en-US', {
			minimumFractionDigits: 2,      
			maximumFractionDigits: 2,
		}));
		this.mergeState({ 
			application: application,
			calculator: calculator, 
			renderKey: this.state.renderKey + 1,
			unsavedChanges: true
		});
	}
	
	monthAndYearToDate(strMonthAndYear) {
		var result = {
			date: null,
			error: null
		};
		
		if (!this.isEmpty(strMonthAndYear)) {
			var intSlashIdx = strMonthAndYear.indexOf('/');
			if (intSlashIdx === -1) {
				result.error = 'Dissertation degree date must be in the form mm/yyyy';
			} else {
				var strMonth = strMonthAndYear.substring(0, intSlashIdx);
				var strYear = strMonthAndYear.substring(intSlashIdx + 1);
				if (!this.isNumeric(strMonth) || !this.isNumeric(strYear)) {
					result.error = 'Dissertation degree date must be in the form mm/yyyy';
				}
				var intMonth = +strMonth;
				if (intMonth < 1 || intMonth > 12) {
					result.error = 'Dissertation degree date must be in the form mm/yyyy';
				}
				var intYear = +strYear;
				if (intYear >= 0 && intYear < 50) intYear += 2000;
				if (intYear >= 50 && intYear < 100) intYear += 1900;
				if (intYear < 0 || intYear > 9999) {
					result.error = 'Dissertation degree date must be in the form mm/yyyy';
				}
				if (result.error === null) result.date = new Date(intYear, intMonth - 1, 1);
			}		
		}
		
		return result;
	}
	
	onSubmit(event) {
		var i;
		var self = this;		

		var thesisDateResult = this.monthAndYearToDate(this.getByAccessor(this.state.application, 'students.THESIS_DATE_MM_YY'));
		if (thesisDateResult.error != null) {
			this.showAlert('Validation Error', thesisDateResult.error);
		} else {
			// make copy of data
			var application = this.copyObject(this.state.application);

			// convert dates, checkboxes
			this.js2mvc(application, studentColumns);
			this.js2mvc(application, detailColumns);
			this.js2mvc(application, contactTempColumns);
			this.js2mvc(application, gpaColumns);
			this.js2mvc(application, qe1Columns);
			this.js2mvc(application, qe2Columns);
			this.js2mvc(application, thesisColumns);
			this.js2mvc(application.financial_notes, fnConvertColumns);

			// convert residency checkboxes
			application.CA_RES_EXP = application.CA_RESIDENT === 'EXPECTED' ? 'Y' : null;
			application.CA_RESIDENT = application.CA_RESIDENT === 'RESIDENT' ? 'Y' : null;
			application.students.CA_RESIDENT = application.students.CA_RESIDENT ? 1 : 0;

			// convert degree dates to MVC
			for (i = 0; i < application.app_education.length; i++) {
				application.app_education[i].DEGREE_DATE = self.dateTimeToMVC(self.getDate(application.app_education[i].DEGREE_DATE));
			}
			
			// make sure GREs are numbered, in case any have been created
			if (Array.isArray(application.gre_test)) {
				for (i = 0; i < application.gre_test.length; i++) {
					if (application.gre_test[i] === null) application.gre_test[i] = {};
					application.gre_test[i].GRE_NUMBER = i + 1;
				}
			}
						
			// if this is a new student, set ID to sequence name
			if (!this.props.params.studentId) {
				application.APP_ID = 'application_id.nextval';
			}
			
			// remove empty RA/PI entries
			this.cullRAPI(application.research_director);
			this.cullRAPI(application.associate_advisor);
			this.cullRAPI(application.financial_only_pi);
			
			// set thesis date
			application.students.THESIS_DATE = thesisDateResult.date ? this.dateTimeToMVC(thesisDateResult.date) : null;
			
			// update dissertation faculty members if value is non-empty and different from label (this means first name search was used)
			this.fixFirstNameSearch(application.students, 'THESIS_CHAIR', 'THESIS_CHAIR_VALUE');
			this.fixFirstNameSearch(application.students, 'INSIDE_READER', 'INSIDE_READER_VALUE');
			this.fixFirstNameSearch(application.students, 'ADD_READER', 'ADD_READER_VALUE');
			this.fixFirstNameSearch(application.students, 'OUTSIDE_READER', 'OUTSIDE_READER_VALUE');
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/GradOffice/UpdateStudent',
				overlay: true,
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, application: application }
			}).done(function (data) {
				if (data.Success) {
					var studentId = data.ID;
					var verb = self.props.params.studentId ? 'updated' : 'inserted';
					self.props.parent.showConfirmation(() => {
						return (<>
							Student <Link to={'/GradOffice/Students/' + studentId}>{studentId}</Link> {verb}.
						</>);
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}
	
	fixFirstNameSearch(students, accessor, valueAccessor) {
		if (!this.isEmpty(students[valueAccessor]) && students[accessor] !== students[valueAccessor]) {
			students[accessor] = students[valueAccessor];
		}
	}
	
	openCalculator(event, accessor, value) {
		this.mergeState({
			calculator: {
				accessor: accessor,
				revert: value,
				show: true
			},
			renderKey: this.state.renderKey + 1
		});
	}
	
	closeCalculator(event) {
		this.mergeState({
			calculator: emptyCalculator,
			renderKey: this.state.renderKey + 1
		});
	}
	
	cancelCalculator(event) {
		var application = this.copyObject(this.state.application);
		this.setByAccessor(application, this.state.calculator.accessor, this.state.calculator.revert);
		this.mergeState({ 
			application: application, 
			calculator: emptyCalculator,
			renderKey: this.state.renderKey + 1 
		});
	}
	
	onChangeFinancialNotes(data) {
		// for each of the new notes
		for (var newIdx = 0; newIdx < data.length; newIdx++) {
			// if entry date is empty
			if (this.isEmpty(data[newIdx].ENTRY_DATE)) {
				// set the entry info
				data[newIdx].ENTERED_BY = this.props.user.name;
				data[newIdx].ENTRY_DATE = this.dateTimeToMVC(new Date());
			} else {			
				// locate the old note
				for (var oldIdx = 0; oldIdx < this.state.application.financial_notes.length; oldIdx++) {
					// if the note ids match and the note has changed
					if (data[newIdx].id === this.state.application.financial_notes[oldIdx].id &&
						data[newIdx].NOTE_ENTRY !== this.state.application.financial_notes[oldIdx].NOTE_ENTRY) {
						// set the entry info
						data[newIdx].ENTERED_BY = this.props.user.name;
						data[newIdx].ENTRY_DATE = this.dateTimeToMVC(new Date());
						break;
					}
				}
			}
		}
		
		data.sort((a, b) => {
			// convert to js dates
			var dtA = this.getDate(a.ENTRY_DATE);
			var dtB = this.getDate(b.ENTRY_DATE);
			
			// sort in descending order
			if (dtB < dtA) return -1;
			if (dtB > dtA) return 1;
			return 0;
		});
		
		this.mergeState({
			application: {
				financial_notes: data
			},
			editorsOpen: this.setEditorStatus('financial_notes', false),
			renderKey: this.state.renderKey + 1,
			unsavedChanges: true
		});
	}
	
	getFellowshipSum(fellowshipId, semester, accessor) {
		var i;
		var details = null;
		
		// locate the details for the requested fellowship/semester
		for (i = 0; i < this.state.application.fellowships.length; i++) {
			if (this.state.application.fellowships[i].FELLOWSHIP_ID === fellowshipId) {
				details = this.state.application.fellowships[i][semester];
			}
		}
		
		// if the details were found
		var sum = 0;
		if (details) {
			// add the requested value from each detail record
			for (i = 4; i < details.length; i++) {
				if (details[i] && !this.isEmpty(details[i][accessor])) sum += details[i][accessor];
			}
		}
		
		return this.formatCurrency(sum);
	}

	addFellowship() {
		if (this.state.unsavedChanges) {
			this.showAlert('Unsaved Changes', 'You have unsaved changes for this student. Please save before proceeding to the Fellowship editor.');
		} else {
			this.props.navigate('/GradOffice/Fellowships/new/' + this.props.params.studentId);
		}
	}
	
	editFellowship(fellowshipId) {
		if (this.state.unsavedChanges) {
			this.showAlert('Unsaved Changes', 'You have unsaved changes for this student. Please save before proceeding to the Fellowship editor.');
		} else {
			this.props.setFellowshipEditable(true);
			this.props.navigate('/GradOffice/Fellowships/' + fellowshipId);
		}
	}

	viewFellowship(fellowshipId) {
		if (this.state.unsavedChanges) {
			this.showAlert('Unsaved Changes', 'You have unsaved changes for this student. Please save before proceeding to the Fellowship editor.');
		} else {
			this.props.setFellowshipEditable(false);
			this.props.navigate('/GradOffice/Fellowships/' + fellowshipId);
		}
	}

	addGSI() {
		if (this.state.unsavedChanges) {
			this.showAlert('Unsaved Changes', 'You have unsaved changes for this student. Please save before proceeding to the GSI editor.');
		} else {
			this.props.navigate('/GradOffice/GSIs/new/' + this.props.params.studentId);
		}
	}		
	
	editGSI(assignmentId) {
		if (this.state.unsavedChanges) {
			this.showAlert('Unsaved Changes', 'You have unsaved changes for this student. Please save before proceeding to the GSI editor.');
		} else {
			this.props.setGSIEditable(true);
			this.props.navigate('/GradOffice/GSIs/' + assignmentId);		
		}
	}
	
	viewGSI(assignmentId) {
		if (this.state.unsavedChanges) {
			this.showAlert('Unsaved Changes', 'You have unsaved changes for this student. Please save before proceeding to the GSI editor.');
		} else {
			this.props.setGSIEditable(false);
			this.props.navigate('/GradOffice/GSIs/' + assignmentId);		
		}
	}

	canClickTabItem(tab) {
		// prevent user from leaving a tab if an editor is open
		if (this.state.activeTab === 'Student' && this.state.editorsOpen.includes('research_director')) {
			this.showAlert('Editor Open', 'Please close the RA/PI editor before leaving the Student tab.');
			return false;
		} else if (this.state.activeTab === 'Education' && this.state.editorsOpen.includes('app_education')) {
			this.showAlert('Editor Open', 'Please close the institution editor before leaving the Education tab.');
			return false;
		} else if (this.state.activeTab === 'Financial Notes' && this.state.editorsOpen.includes('financial_notes')) {
			this.showAlert('Editor Open', 'Please close the financial note editor before leaving the Financial Notes tab.');
			return false;
		}
		return true;
	}
	
	onClickTabItem(tab) {
		this.props.navigate('/GradOffice/Students/' + (this.props.params.studentId || 'new') + '/' + tab);
	}

	renderWithCalculator(accessor, value) {
		var optionalDisabledProp = this.state.editable === false ? { disabled: true } : {};
		return (
			<InputGroup style={{ marginTop: '-8px' }}>
				<Form.Control type="text" value={value} {...optionalDisabledProp}
					onChange={(event) => this.onChange(accessor, event.target.value)} />
				{this.state.editable !== false && <Button onClick={(event) => this.openCalculator(event, accessor, value)}><Calculator style={{ marginTop: '-4px' }}/></Button>}
			</InputGroup>
		);	
	}
	
	renderGPA () {
		// build fake data table for GPA
		const gpaDisplayData = [
			{ id: 0, GPA_TYPE: 'Undergrad', CONFIRMED: this.state.application.app_details.UNGRAD_GPA_CONF ? 'Y' : 'N' },
			{ id: 1, GPA_TYPE: 'Grad', CONFIRMED: this.state.application.app_details.GRAD_GPA_CONF ? 'Y' : 'N' }
		];
		
		return (<ChemTable renderKey={this.state.renderKey} parent={this} data={gpaDisplayData} columns={gpaDisplayColumns} name='GradOffice_Student_Materials_GPA' />);
	}
	
	renderStudentTitle() {
		var appHeader = 'Student';
		if (this.state.application.students && this.state.application.students.STUDENT_ID) appHeader += ' ' + this.state.application.students.STUDENT_ID;
		var name = this.join(', ', this.state.application.LNAME, this.join(' ', this.state.application.FNAME, this.state.application.MNAME));
		if (name.length > 0) appHeader += ' for ' + name;
		return appHeader;
	}
	
	renderStudentHeader() {
		var semester = this.state.application.SEMESTER === 'FAL' ? 'FALL' : (this.state.application.SEMESTER === 'SPR' ? 'SPRING' : '');
		var division = '';
		if (this.state.application.students && !this.isEmpty(this.state.application.students.DIVISION)) {
			division = this.state.application.students.DIVISION;
		}
		var regStatus = '';
		if (this.state.application.students && !this.isEmpty(this.state.application.students.REG_STATUS)) {
			regStatus = this.state.application.students.REG_STATUS;
		}
		return(<div style={{ marginBottom: '10px' }}>
		   <span style={{ fontWeight: 'bold' }}>Entered</span> {semester} {this.state.application.ACADEMIC_YEAR}
		   &nbsp;&nbsp;&nbsp;
		   <span style={{ fontWeight: 'bold' }}>Visa</span> {this.state.application.VISA}
		   &nbsp;&nbsp;&nbsp;
		   <span style={{ fontWeight: 'bold' }}>Citizenship</span> {this.state.application.CITIZENSHIP}
		   &nbsp;&nbsp;&nbsp;
		   <span style={{ fontWeight: 'bold' }}>Division</span> {division}
		   &nbsp;&nbsp;&nbsp;
		   <span style={{ fontWeight: 'bold' }}>Registration</span> {regStatus}
		</div>);
	}

	render() {
		
		var exportFellowshipData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Fellowships',
			columns: fellowshipExportColumns,
			data: this.state.application.fellowships ? this.processData(fellowshipColumns, this.state.application.fellowships) : []
		});	
		
		return (<>
			<Container fluid style={{ border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '20px' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>{this.renderStudentTitle()}&nbsp;&nbsp;
					{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
					  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}
				  </div>
				  {this.renderStudentHeader()}
				  <Tabs activeTab={this.state.activeTab} canClickTabItem={(tab) => this.canClickTabItem(tab)} onClickTabItem={(tab) => this.onClickTabItem(tab)}>
				    <div label='Student'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={studentColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
						<Row>
						  <Col>
						    <ChemEdit parent={this} columns={commentColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} labelColWidth={1} valueColWidth={11} />
						  </Col>
						</Row>
						<Row>
						  <Col style={{ paddingTop: '10px' }}>
							<h6>Research Director</h6>
							  <ChemEdit parent={this} columns={resDirColumns} data={this.state.application} user={this.props.user} labelColWidth={2} valueColWidth={10}
								editable={this.state.editable} renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
						<Row>
						  <Col style={{ paddingTop: '10px' }}>
							<h6>Associate Advisor</h6>
							  <ChemEdit parent={this} columns={assocAdvColumns} data={this.state.application} user={this.props.user} labelColWidth={2} valueColWidth={10}
								editable={this.state.editable} renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
						<Row>
						  <Col style={{ paddingTop: '10px' }}>
							<h6>Financial (only) PI</h6>
							  <ChemEdit parent={this} columns={finOnlyPIColumns} data={this.state.application} user={this.props.user} labelColWidth={2} valueColWidth={10}
								editable={this.state.editable} renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Details'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={detailColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Contact'>
					  <Container fluid>
						<Row>
						  <Col>
							<h5>Permanent Address</h5>
							<ChemEdit parent={this} columns={contactPermColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />							
						  </Col>
						</Row>
						<Row>
						  <Col>
							<h5>Temporary Address</h5>
							<ChemEdit parent={this} columns={contactTempColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />							
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Education'>
					  <Container fluid>
						<Row>
						  <Col>
							<h5>GPA</h5>
							{this.state.calculator.show &&
								<ChemEdit parent={this} columns={calculatorColumns} data={this.state.calculator} user={this.props.user} editable={this.state.editable}
									renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeCalculator(accessor, value)} />							
							}								
							<ChemEdit parent={this} columns={gpaColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />							
						  </Col>
						</Row>
						<Row>
						  <Col style={{ marginTop: '10px' }}>
						    <h5>Institutions Attended</h5>
							<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyDegree} data={this.state.application.app_education}
							  tableColumns={degreeTableColumns} editColumns={degreeEditColumns} onEdit={(editorIsOpen) => this.onEdit('app_education', editorIsOpen)}
							  onChange={(data, callback) => this.onChange('app_education', data)} tableName='GradOffice_Student_Degrees' order='INSTIT_NUMBER' editable={this.state.editable} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Fellowship'>
						<Container fluid>
						  <Row>
							<Col xs={1} style={{ marginTop: 6 }}>
								<form action={this.getConfig().host + '/Home/ExcelData'} method='POST'>
									{exportFellowshipData.map((excelFormField, index) => {
										return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									</OverlayTrigger>
								</form>
							</Col>								  
						  </Row>
						  <Row>
							<Col>
							  <ChemTable parent={this} user={this.props.user} renderKey={this.state.renderKey} data={this.state.application.fellowships} columns={fellowshipColumns}
								tableName='GradOffice_Student_Fellowships' editable={this.state.editable} />
							</Col>
						  </Row>
						</Container>
					</div>
					<div label='GSI'>
					  <Container fluid>
					    <Row style={{ paddingBottom: 20 }}>
						  <Col>
						    <ChemEdit parent={this} user={this.props.user} columns={gsiComments} data={this.state.application} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} labelColWidth={3} valueColWidth={9} />
						  </Col>
						</Row>
						<Row>
						  <Col>
						    <ChemTable parent={this} user={this.props.user} renderKey={this.state.renderKey} data={this.state.application.gs_assig} columns={gsiColumns} 
								tableName='GradOffice_Student_GSI' editable={this.state.editable} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Qual Exam'>
					  <Container fluid>
						<Row>
						  <Col>
						    <h5>Qualifying Exam 1</h5>
							<ChemEdit parent={this} columns={qe1Columns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />					
						  </Col>
						</Row>
						<Row>
						  <Col style={{ marginTop: '20px' }}>
						    <h5>Qualifying Exam 2</h5>
							<ChemEdit parent={this} columns={qe2Columns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />					
						  </Col>
						</Row>
						<Row>
						  <Col style={{ marginTop: '20px' }}>
						    <h5>Supplemental Information</h5>
							<ChemEdit parent={this} columns={suppInfoColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)}
								labelColWidth={3} valueColWidth={9} />					
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Dissertation'>
					  <Container fluid>
						<Row>
						  <Col>
							<Form.Check style={{ paddingTop: '8px' }} type="checkbox" checked={this.state.dissertationSearchByFirstName} label="Check this box to search by first name in dropdown menus"
								onClick={(event) => this.onChangeDissertationSearch(event.target.checked)} />
							<ChemEdit parent={this} columns={this.state.thesisColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)}
								labelColWidth={4} valueColWidth={8} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Financial Notes'>
					  <Container fluid>
						<Row>
						  <Col>
							<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyFinancialNote} data={this.state.application.financial_notes}
								tableColumns={fnTableColumns} editColumns={fnEditColumns} onEdit={(editorIsOpen) => this.onEdit('financial_notes', editorIsOpen)}
								onChange={(data, callback) => this.onChangeFinancialNotes(data)} tableName='GradOffice_Student_FinancialNotes' editable={this.state.editable} />
						  </Col>
						</Row>
					  </Container>
					</div>
				  </Tabs>
				</Col>
			  </Row>
			  <Row>
				<Col style={{ paddingTop: '20px', paddingBottom: '20px' }}>
					{this.state.editable && <Button id="save" variant="warning" type="submit" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const emptyCalculator = {
	accessor: null,
	revert: null,
	A: '',
	AMinus: '',
	BPlus: '',
	B: '',
	BMinus: '',
	CPlus: '',
	C: '',
	CMinus: '',
	DPlus: '',
	D: '',
	DMinus: '',
	F: '',
	show: false
};

const emptyDegree = {
	drag: null,
	id: null,
	APP_ID: null,
	INSTIT_NUMBER: null,
	INSTITUTION: '',
	LOCATION: '',
	DEGREE: '',
	DEGREE_DATE: null,
	DEGREE_TYPE: '',
	MAJOR: '',
	SHOW_SCHOOL: null,
	LAST_MODIF: null,
	MODIF_BY: '',
	GPA_FLAG: null,
	TRANSCRIPT: null,
	PDF_ID: null,
	OFF_TRANSCRIPT: null,
	INSTIT_ID: 'request_id_seq.nextval'
};

const emptyFinancialNote = {
	id: null,
	NOTE_ID: 'financial_note_seq.nextval',
	NOTE_ENTRY: '',
	ENTRY_DATE: null,
	ENTERED_BY: ''
};

const studentColumns = [
	{ Header: 'Academic Year', accessor: 'students.ACAD_YEAR', row: 0, col: 0 },
	{ Header: 'Division', accessor: 'students.DIVISION', type: 'dropdown', row: 0, col: 1,
		options: { value: 'DIVISION', label: 'DIVISION', entity: 'ADMISSIONS.DIVISIONS' }},
	{ Header: 'Chem Bio GP', accessor: 'students.CBGP_FLAG', type: 'checkbox', row: 0, col: 2 },
	{ Header: 'Degree Program', accessor: 'students.DEGREE', type: 'select', row: 1, col: 0,
		options: [{ value: null, label: 'Select...' },{ value: 'PhD', label: 'PhD' },{ value: 'MS', label: 'MS' }]},
	{ Header: 'Subdivision', accessor: 'students.SUBDIVISION', row: 1, col: 1 },
	{ Header: 'Concentration', accessor: 'students.CONCENTRATION', row: 1, col: 2 },
	{ Header: 'First Year Advisor', accessor: 'students.FIRST_YEAR_ADV', type: 'select', row: 2, col: 0,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'students.FIRST_YEAR_ADV' }
	]}}},
	{ Header: 'Student ID', accessor: 'students.STUDENT_CARD', row: 2, col: 1, maxlength: 10 },
	{ Header: 'Registration Status', accessor: 'students.REG_STATUS', type: 'select', row: 2, col: 2,
		options: { value: 'REG_STATUS_NAME', label: 'REG_STATUS_NAME', entity: 'ADMISSIONS.REG_STATUS' }},
	{ Header: 'Campus Location', accessor: 'address_phone.LAB_ADDRESS', row: 3, col: 0, maxlength: 80 },
	{ Header: 'Employee ID', accessor: 'gs_emp.EMP_ID', row: 3, col: 1, maxlength: 10 },
	{ Header: 'Current Pay Plan', accessor: 'students.STUDENT_PAY_PLAN', type: 'select', row: 3, col: 2,
		options: { value: 'PLAN_NAME', label: 'PLAN_NAME', entity: 'ADMISSIONS.PAY_PLAN',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Campus Phone 1', accessor: 'address_phone.LAB_PHONE1', row: 4, col: 0 },
	{ Header: 'CA Resident', accessor: 'students.CA_RESIDENT', type: 'checkbox', row: 4, col: 1 },
	{ Header: 'Email Student', accessor: 'address_phone.TEMP_EMAIL_ADD', row: 4, col: 2, editable: false,
		Cell: props => <a href={'mailto:' + props.value}>{props.value}</a> },
	{ Header: 'Campus Phone 2', accessor: 'address_phone.LAB_PHONE2', row: 5, col: 0 },
	{ Header: 'Modified', accessor: 'students.MODIFIED', row: 5, col: 2, editable: false }
];

const commentColumns = [
	{ Header: 'Comments', accessor: 'students.COMMENTS', type: 'textarea', rows: 5 }
];

const resDirColumns = [
	{ Header: '1', valueAccessor: 'research_director[0].PERSON_ID', accessor: 'research_director[0].PI_NAME', type: 'dropdown', row: 0, col: 0,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'research_director[0].PERSON_ID' }
	]}}},
	{ Header: '2', valueAccessor: 'research_director[1].PERSON_ID', accessor: 'research_director[1].PI_NAME', type: 'dropdown', row: 0, col: 1,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'research_director[1].PERSON_ID' }
	]}}},
	{ Header: '3', valueAccessor: 'research_director[2].PERSON_ID', accessor: 'research_director[2].PI_NAME', type: 'dropdown', row: 0, col: 2,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'research_director[2].PERSON_ID' }
	]}}}
];

const assocAdvColumns = [
	{ Header: '1', valueAccessor: 'associate_advisor[0].PERSON_ID', accessor: 'associate_advisor[0].PI_NAME', type: 'dropdown', row: 0, col: 0,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'associate_advisor[0].PERSON_ID' }
	]}}},
	{ Header: '2', valueAccessor: 'associate_advisor[1].PERSON_ID', accessor: 'associate_advisor[1].PI_NAME', type: 'dropdown', row: 0, col: 1,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'associate_advisor[1].PERSON_ID' }
	]}}},
	{ Header: '3', valueAccessor: 'associate_advisor[2].PERSON_ID', accessor: 'associate_advisor[2].PI_NAME', type: 'dropdown', row: 0, col: 2,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'associate_advisor[2].PERSON_ID' }
	]}}}
];
	
const finOnlyPIColumns = [	
	{ Header: '1', valueAccessor: 'financial_only_pi[0].PERSON_ID', accessor: 'financial_only_pi[0].PI_NAME', type: 'dropdown', row: 0, col: 0,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'financial_only_pi[0].PERSON_ID' }
	]}}},
	{ Header: '2', valueAccessor: 'financial_only_pi[1].PERSON_ID', accessor: 'financial_only_pi[1].PI_NAME', type: 'dropdown', row: 0, col: 1,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'financial_only_pi[1].PERSON_ID' }
	]}}},
	{ Header: '3', valueAccessor: 'financial_only_pi[2].PERSON_ID', accessor: 'financial_only_pi[2].PI_NAME', type: 'dropdown', row: 0, col: 2,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'financial_only_pi[2].PERSON_ID' }
	]}}}
];

const ethnicityColumns = [
	{ Header: 'Ethnicity', accessor: 'ETHNICITY_NAME', width: 200 },
	{ Header: '', accessor: 'DELETE_FLAG', show: false }
];

const detailColumns = [
	{ Header: 'Last Name', accessor: 'LNAME', row: 0, col: 0, required: true },
	{ Header: 'First Name', accessor: 'FNAME', row: 0, col: 1, required: true },
	{ Header: 'Middle Name', accessor: 'MNAME', row: 1, col: 0 },
	{ Header: 'Preferred Name', accessor: 'PREFERRED_NAME', row: 1, col: 1 },
	{ Header: 'Title', accessor: 'TITLE_ID', type: 'dropdown', row: 2, col: 0,
		options: { value: 'TITLE_NAME', label: 'TITLE_NAME', entity: 'ADMISSIONS.TITLES', order: 'TITLE_NAME' }},
	{ Header: 'Gender', accessor: 'GENDER_TYPE_ID', type: 'select', row: 2, col: 1,
		options: { value: 'GENDER_TYPE_ID', label: 'DESCRIPTION', entity: 'ADMISSIONS.GENDER_TYPE', order: 'DESCRIPTION' }},
	{ Header: 'Pronouns', accessor: 'PRONOUNS', row: 3, col: 1 },		
	{ Header: 'Student Semester', accessor: 'SEMESTER', type: 'select', row: 4, col: 0,
		options: [{ value: null, label: 'Select...'}, { value: 'SPR', label: 'Spring' }, { value: 'FAL', label: 'Fall' }]},
	{ Header: 'Student Year', accessor: 'ACADEMIC_YEAR', row: 4, col: 1 },
	{ Header: 'Visa Type', accessor: 'VISA', type: 'dropdown', row: 5, col: 0,
		options: { value: 'VISA_NAME', label: 'VISA_NAME', entity: 'ADMISSIONS.VISA', order: 'VISA_NAME' }},
	{ Header: 'Citizenship', accessor: 'CITIZENSHIP', row: 5, col: 1 },
	{ Header: 'Birthdate', accessor: 'BIRTHDATE', type: 'date', row: 6, col: 0 },
	{ Header: 'Birth City', accessor: 'BIRTH_CITY', row: 6, col: 1 },
	{ Header: 'Birth State', accessor: 'BIRTH_STATE', row: 7, col: 0 },
	{ Header: 'Birth Country', accessor: 'BIRTH_COUNTRY', row: 7, col: 1 },
	{ Header: 'CA Residency', accessor: 'CA_RESIDENT', type: 'select', row: 8, col: 0,
		options: [{ value: null, label: 'Select...' }, { value: 'RESIDENT', label: 'Resident' }, 
			{ value: 'EXPECTED', label: 'Expected' }, { value: 'NON-RESIDENT', label: 'Non-Resident' }]},
	{ Header: 'Residency Date Expected', accessor: 'CA_RES_START', type: 'date', row: 8, col: 1 },
	{ Header: 'Contact Address', accessor: 'ALT_CTACT_ADD', row: 9, col: 0 },
	{ Header: 'Contact Name', accessor: 'ALT_CTACT_NAME', row: 9, col: 1 },
	{ Header: 'Fellowship 1', accessor: 'app_details.FELLOWSHIP1', row: 10, col: 0 },
	{ Header: 'Fellowship 2', accessor: 'app_details.FELLOWSHIP2', row: 10, col: 1 },
	{ Header: 'Ethnicity', accessor: 'ethnicity', type: 'table', name: 'GradOffice_Student_Ethnicity', columns: ethnicityColumns, row: 11, col: 0,
		options: { value: 'ETHNICITY_ID', label: 'ETHNICITY_NAME', entity: 'ADMISSIONS.APP_ETHNICITY_SELECT_VW', order: 'ETHNICITY_NAME' }},
	{ Header: 'McNair Program', accessor: 'financial_extract.MCNAIR_PROG_FLAG', type: 'checkbox', row: 11, col: 1 },
	{ Header: 'URM', accessor: 'URM', type: 'checkbox', row: 12, col: 0 },
	{ Header: 'Hispanic', accessor: 'HISPANIC_FLAG', type: 'checkbox', row: 12, col: 1 },
	{ Header: 'Previous Submission', accessor: 'PREV_UCB_GRAD_APPL', row: 13, col: 0, editable: false },
	{ Header: 'Date Submitted', accessor: 'DOC_RECEIVE_DATE', type: 'date', row: 13, col: 1 },
	{ Header: 'Internal Comments', accessor: 'COMMENTS', type: 'textarea', row: 14, col: 0 },
	{ Header: 'Applicant Correspondence', accessor: 'app_comment.APP_COMMENT', type: 'textarea', row: 14, col: 1 },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 15, col: 0, editable: false }
];

const contactPermColumns = [
	{ Header: 'Street Line 1', accessor: 'address_phone.STREET1', row: 0, col: 0 },
	{ Header: 'Street Line 2', accessor: 'address_phone.STREET2', row: 0, col: 1 },
	{ Header: 'City', accessor: 'address_phone.CITY', row: 0, col: 2 },
	{ Header: 'State', accessor: 'address_phone.STATE', row: 1, col: 0 },
	{ Header: 'Zip', accessor: 'address_phone.ZIP', row: 1, col: 1 },
	{ Header: 'Country', accessor: 'address_phone.COUNTRY', row: 1, col: 2 },
	{ Header: 'Phone Country Code', accessor: 'address_phone.TEL_CNTRY_CODE', row: 2, col: 0 },
	{ Header: 'Phone Area Code', accessor: 'address_phone.TEL_AREA_CODE', row: 2, col: 1 },
	{ Header: 'Phone Number', accessor: 'address_phone.TEL_NUM', row: 2, col: 2 },
	{ Header: 'Cell Country Code', accessor: 'address_phone.CELL_TEL_CNTRY_CD', row: 3, col: 0 },
	{ Header: 'Cell Area Code', accessor: 'address_phone.CELL_TEL_AREA_CD', row: 3, col: 1 },
	{ Header: 'Cell Number', accessor: 'address_phone.CELL_TEL_NUM', row: 3, col: 2 },
	{ Header: 'Fax Country Code', accessor: 'address_phone.FAX_CNTRY_CODE', row: 4, col: 0 },
	{ Header: 'Fax Area Code', accessor: 'address_phone.FAX_AREA_CODE', row: 4, col: 1 },
	{ Header: 'Fax Number', accessor: 'address_phone.FAX_NUM', row: 4, col: 2 },
	{ Header: 'Email Address', accessor: 'address_phone.EMAIL', row: 5, col: 1 }
];

const contactTempColumns = [
	{ Header: 'Street Line 1', accessor: 'address_phone.TEMP_STREET1', row: 0, col: 0 },
	{ Header: 'Street Line 2', accessor: 'address_phone.TEMP_STREET2', row: 0, col: 1 },
	{ Header: 'City', accessor: 'address_phone.TEMP_CITY', row: 0, col: 2 },
	{ Header: 'State', accessor: 'address_phone.TEMP_STATE', row: 1, col: 0 },
	{ Header: 'Zip', accessor: 'address_phone.TEMP_ZIP', row: 1, col: 1 },
	{ Header: 'Country', accessor: 'address_phone.TEMP_COUNTRY', row: 1, col: 2 },
	{ Header: 'Phone Country Code', accessor: 'address_phone.TEMP_PH_CODE', row: 2, col: 0 },
	{ Header: 'Phone Area Code', accessor: 'address_phone.TEMP_AREA_CODE', row: 2, col: 1 },
	{ Header: 'Phone Number', accessor: 'address_phone.TEMP_PHONE', row: 2, col: 2 },
	{ Header: 'Fax Country Code', accessor: 'address_phone.TEMP_FAX_CNTRY_CODE', row: 3, col: 0 },
	{ Header: 'Fax Area Code', accessor: 'address_phone.TEMP_FAX_AREA_CODE', row: 3, col: 1 },
	{ Header: 'Fax Number', accessor: 'address_phone.TEMP_FAX_NUM', row: 3, col: 2 },
	{ Header: 'Email Address', accessor: 'address_phone.TEMP_EMAIL_ADD', row: 4, col: 0 },
	{ Header: 'Address Comment', accessor: 'address_phone.ADDRESS_COMMENT', type: 'textarea', row: 4, col: 1 },
	{ Header: 'Good Until', accessor: 'address_phone.ADDRESS_END', type: 'date', row: 4, col: 2 }
];

const calculatorColumns = [
	{ Header: 'A-', accessor: 'AMinus', row: 0, col: 0 },
	{ Header: 'A', accessor: 'A', row: 0, col: 1 },
	{ Header: 'B-', accessor: 'BMinus', row: 1, col: 0 },
	{ Header: 'B', accessor: 'B', row: 1, col: 1 },
	{ Header: 'B+', accessor: 'BPlus', row: 1, col: 2 },
	{ Header: 'C-', accessor: 'CMinus', row: 2, col: 0 },
	{ Header: 'C', accessor: 'C', row: 2, col: 1 },
	{ Header: 'C+', accessor: 'CPlus', row: 2, col: 2 },
	{ Header: 'D-', accessor: 'DMinus', row: 3, col: 0 },
	{ Header: 'D', accessor: 'D', row: 3, col: 1 },
	{ Header: 'D+', accessor: 'DPlus', row: 3, col: 2 },
	{ Header: '', accessor: '', row: 4, col: 0, Cell: props => <></> },
	{ Header: 'F', accessor: 'F', row: 4, col: 1 },
	{ Header: '', accessor: '', row: 4, col: 2,
		Cell: props => <>
			<Button style={{ marginTop: '-4px', marginRight: '8px' }} id="cancelCalculator" variant="warning" type="button" onClick={(event) => props.parent.cancelCalculator(event)}>Cancel</Button>
			<Button style={{ marginTop: '-4px' }} id="closeCalculator" variant="warning" type="button" onClick={(event) => props.parent.closeCalculator(event)}>Close</Button>
		</> }
];

const gpaColumns = [
	{ Header: 'UG GPA', accessor: 'app_details.UNGRAD_GPA', row: 0, col: 0,
		Cell: props => props.parent.renderWithCalculator('app_details.UNGRAD_GPA', props.value) },
	{ Header: 'UG 2Y GPA', accessor: 'app_details.UNGRAD_AFTER_2YR_GPA', row: 0, col: 1,
		Cell: props => props.parent.renderWithCalculator('app_details.UNGRAD_AFTER_2YR_GPA', props.value) },
	{ Header: 'Confirmed', accessor: 'app_details.UNGRAD_GPA_CONF', row: 0, col: 2, type: 'checkbox' },
	{ Header: 'Grad GPA', accessor: 'app_details.GRAD_GPA', row: 1, col: 0,
		Cell: props => props.parent.renderWithCalculator('app_details.GRAD_GPA', props.value) },
	{ Header: '', accessor: '', row: 1, col: 1, Cell: props => <></> },
	{ Header: 'Confirmed', accessor: 'app_details.GRAD_GPA_CONF', row: 1, col: 2, type: 'checkbox' },	
];

const degreeTableColumns = [
	{ Header: 'Order', accessor: 'INSTIT_NUMBER', width: 60 },
	{ Header: 'School', accessor: 'INSTITUTION', width: 400 },
	{ Header: 'Location', accessor: 'LOCATION', width: 150 },
	{ Header: 'Major', accessor: 'MAJOR', width: 100 },
	{ Header: 'Degree', accessor: 'DEGREE', width: 100 },
	{ Header: 'Degree Date', accessor: 'DEGREE_DATE', width: 100,
		Cell: props => props.value ? props.parent.getDate(props.value).toLocaleString('en-US', { month: '2-digit', year: 'numeric'}) : '' },
	{ Header: 'UG/G', accessor: 'DEGREE_TYPE', width: 50 },
	{ Header: 'Transcript', accessor: 'TRANSCRIPT', width: 80 }
];

const degreeEditColumns = [
	{ Header: 'School', accessor: 'INSTITUTION', row: 0, col: 0 },
	{ Header: 'Location', accessor: 'LOCATION', row: 0, col: 1 },
	{ Header: 'Major', accessor: 'MAJOR', row: 0, col: 2 },
	{ Header: 'Degree', accessor: 'DEGREE', row: 1, col: 0 },
	{ Header: 'Degree Date', accessor: 'DEGREE_DATE', type: 'date', row: 1, col: 1 },
	{ Header: 'UG/G', accessor: 'DEGREE_TYPE', row: 1, col: 2 },
	{ Header: 'Transcript', accessor: 'TRANSCRIPT', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 2, col: 1, editable: false },
];

const gpaDisplayColumns = [
	{ Header: '', accessor: 'GPA_TYPE', width: 100 },
	{ Header: 'Confirmed', accessor: 'CONFIRMED', width: 100 }
];

const fellowshipExportColumns = [
	{ Header: 'ID', accessor: 'FELLOWSHIP_ID', width: 10 },
	{ Header: 'Year', accessor: 'FELLOWSHIP_YEAR', width: 15 },
	{ Header: 'C/N', accessor: 'STUDENT_STATUS', width: 10 },
	{ Header: 'Fund Name', accessor: 'FUND', width: 80 },
	{ Header: 'F Fees', accessor: 'FALL_FEES', width: 20 },
	{ Header: 'F Tuition', accessor: 'FALL_TUITION', width: 20 },
	{ Header: 'F Stipend', accessor: 'FALL_STIPEND', width: 20 },
	{ Header: 'S Fees', accessor: 'SPRING_FEES', width: 20 },
	{ Header: 'S Tuition', accessor: 'SPRING_TUITION', width: 20 },
	{ Header: 'S Stipend', accessor: 'SPRING_STIPEND', width: 20 },
	{ Header: 'Start', accessor: 'START_DATE', width: 20 },
	{ Header: 'End', accessor: 'END_DATE', width: 20 }
];

const fellowshipColumns = [
	{ Header: props => props.parent.state.editable !== false && props.parent.props.params.studentId && 
		<PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addFellowship()} />, accessor: 'id', 
		Cell: props => props.parent.state.editable !== false && 
		<PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editFellowship(props.row.values.FELLOWSHIP_ID)} />, width: 25 },
	{ Header: 'ID', accessor: 'FELLOWSHIP_ID', width: 50, Cell: props => <HashLink onClick={() => props.parent.viewFellowship(props.row.values.FELLOWSHIP_ID)}>{props.value}</HashLink> },
	{ Header: 'Year', accessor: 'FELLOWSHIP_YEAR', width: 75 },
	{ Header: 'C/N', accessor: 'STUDENT_STATUS', width: 50, Cell: props => <>{ props.value === '0' ? 'C' : 'N' }</> },
	{ Header: 'Fund Name', accessor: 'FUND', width: 400 },
	{ Header: 'F Fees', accessor: 'FALL_FEES', width: 100, Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>
		{props.parent.getFellowshipSum(props.row.values.FELLOWSHIP_ID, 'fall', 'FEES')}</div> },
	{ Header: 'F Tuition', accessor: 'FALL_TUITION', width: 100, Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>
		{props.parent.getFellowshipSum(props.row.values.FELLOWSHIP_ID, 'fall', 'TUITION')}</div> },
	{ Header: 'F Stipend', accessor: 'FALL_STIPEND', width: 100, Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>
		{props.parent.getFellowshipSum(props.row.values.FELLOWSHIP_ID, 'fall', 'STIPEND')}</div> },
	{ Header: 'S Fees', accessor: 'SPRING_FEES', width: 100, Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>
		{props.parent.getFellowshipSum(props.row.values.FELLOWSHIP_ID, 'spring', 'FEES')}</div> },
	{ Header: 'S Tuition', accessor: 'SPRING_TUITION', width: 100, Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>
		{props.parent.getFellowshipSum(props.row.values.FELLOWSHIP_ID, 'spring', 'TUITION')}</div> },
	{ Header: 'S Stipend', accessor: 'SPRING_STIPEND', width: 100, Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>
		{props.parent.getFellowshipSum(props.row.values.FELLOWSHIP_ID, 'spring', 'STIPEND')}</div> },
	{ Header: 'Start', accessor: 'START_DATE', width: 100, Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
	{ Header: 'End', accessor: 'END_DATE', width: 100, Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) }
];

const gsiComments = [
	{ Header:  "GSI Comments<br /><span style={{ fontSize: '8pt', fontWeight: 'italics' }}>(available to Instructional Support)</span>", type: 'textarea', accessor: 'students.GSI_COMMENTS' }
];

const gsiColumns = [
	{ Header: props => props.parent.state.editable !== false && props.parent.props.params.studentId &&
		<PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addGSI()} />, accessor: 'id', 
		Cell: props => props.parent.state.editable !== false && 
		<PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editGSI(props.row.values.ASSIGNMENT_ID)} />, width: 25 },
	{ Header: 'ID', accessor: 'ASSIGNMENT_ID' , width: 125, Cell: props => <HashLink onClick={() => props.parent.viewGSI(props.row.values.ASSIGNMENT_ID)}>{props.value}</HashLink> },
	{ Header: 'Student ID', accessor: 'STUDENT_CARD', width: 125 },
	{ Header: 'Emp ID', accessor: 'EMP_ID', width: 125 },
	{ Header: 'Year', accessor: 'COURSE_YEAR', width: 125 },
	{ Header: 'Semester', accessor: 'COURSE_SEMESTER', width: 125 },
	{ Header: 'Course', accessor: 'COURSE_NAME', width: 125 },
	{ Header: 'Name', accessor: 'NAME', width: 200 },
	{ Header: 'Visa', accessor: 'VISA', width: 125 },
	{ Header: 'Fellowships', accessor: 'FUND', width: 125 },
	{ Header: 'Approved_by', accessor: 'APPROVED_BY', width: 200 },
	{ Header: 'Head GSI', accessor: 'HEAD_GSI', width: 125 },
	{ Header: 'Pay Plan', accessor: 'CURRENT_PAY_PLAN', width: 125 },
	{ Header: 'Appt Pct', accessor: 'PAY_PERCENTAGE', width: 125 },
	{ Header: 'Monthly Rate', accessor: 'MONTHLY_RATE', width: 125 },
	{ Header: 'Fee Rem.', accessor: 'FEE_REMISSION', width: 125 },
	{ Header: 'Title', accessor: 'TITLE_CODE', width: 125 },
	{ Header: 'CA Res.', accessor: 'CA_RESIDENT', width: 125 },
	{ Header: 'Year Started', accessor: 'ACADEMIC_YEAR', width: 125 },
	{ Header: 'Exempt', accessor: 'EXEMPT', width: 125 },
	{ Header: 'Email', accessor: 'EMAIL', width: 250 },
	{ Header: 'Citizenship', accessor: 'CITIZENSHIP', width: 125 },
	{ Header: 'Res. Director', accessor: 'RES_DIRECTOR', width: 200 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 250 }
];

const qe1Columns = [
	{ Header: 'Seminar Date', accessor: 'students.SEMINAR_DATE', type: 'date', row: 0, col: 0},
	{ Header: 'QE Date', accessor: 'qual_exam[0].EXAM_DATE', type: 'date', row: 1, col: 0 },
	{ Header: 'Chair', accessor: 'qual_exam[0].CHAIR', valueAccessor: 'qual_exam[0].CHAIR_ID', type: 'dropdown', row: 1, col: 1,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'qual_exam[0].CHAIR_ID' }
	]}}},
	{ Header: 'QE Time', accessor: 'qual_exam[0].EXAM_TIME', type: 'time', row: 2, col: 0 },
	{ Header: '1st Member', accessor: 'qual_exam[0].MEMBER1', valueAccessor: 'qual_exam[0].MEMBER1_ID', type: 'dropdown', row: 2, col: 1,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'qual_exam[0].MEMBER1_ID' }
	]}}},
	{ Header: 'QE Location', accessor: 'qual_exam[0].EXAM_LOC', row: 3, col: 0 },
	{ Header: '2nd Member', accessor: 'qual_exam[0].MEMBER2', valueAccessor: 'qual_exam[0].MEMBER2_ID', type: 'dropdown', row: 3, col: 1,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'qual_exam[0].MEMBER2_ID' }
	]}}},
	{ Header: 'Result', accessor: 'qual_exam[0].RESULT', type: 'select', row: 4, col: 0,
		options: [{ value: null, label: 'Select...' }, { value: 'Pass', label: 'Pass' },
			{ value: 'Fail', label: 'Fail' }, { value: 'Partial Fail', label: 'Partial Fail' }]},
	{ Header: 'Academic Senate Representative', accessor: 'qual_exam[0].OUTSIDE_MEMBER', row: 4, col: 1 },
	{ Header: 'ASR Department', accessor: 'qual_exam[0].OUTS_DEPT', row: 5, col: 1 },
	{ Header: 'ASR Email', accessor: 'qual_exam[0].OUTS_EMAIL', row: 6, col: 1 }
];

const qe2Columns = [
	{ Header: 'QE Date', accessor: 'qual_exam[1].EXAM_DATE', type: 'date', row: 0, col: 0 },
	{ Header: 'Chair', accessor: 'qual_exam[1].CHAIR', valueAccessor: 'qual_exam[1].CHAIR_ID', type: 'dropdown', row: 0, col: 1,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'qual_exam[1].CHAIR_ID' }
	]}}},
	{ Header: 'QE Time', accessor: 'qual_exam[1].EXAM_TIME', type: 'time', row: 1, col: 0 },
	{ Header: '1st Member', accessor: 'qual_exam[1].MEMBER1', valueAccessor: 'qual_exam[1].MEMBER1_ID', type: 'dropdown', row: 1, col: 1,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'qual_exam[1].MEMBER1_ID' }
	]}}},
	{ Header: 'QE Location', accessor: 'qual_exam[1].EXAM_LOC', row: 2, col: 0 },
	{ Header: '2nd Member', accessor: 'qual_exam[1].MEMBER2', valueAccessor: 'qual_exam[1].MEMBER2_ID', type: 'dropdown', row: 2, col: 1,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'qual_exam[1].MEMBER2_ID' }
	]}}},
	{ Header: 'Result', accessor: 'qual_exam[1].RESULT', type: 'select', row: 3, col: 0,
		options: [{ value: null, label: 'Select...' }, { value: 'Pass', label: 'Pass' },
			{ value: 'Fail', label: 'Fail' }, { value: 'Partial Fail', label: 'Partial Fail' }]},
	{ Header: 'Academic Senate Representative', accessor: 'qual_exam[1].OUTSIDE_MEMBER', row: 3, col: 1 },
	{ Header: 'ASR Department', accessor: 'qual_exam[1].OUTS_DEPT', row: 4, col: 1 },
	{ Header: 'ASR Email', accessor: 'qual_exam[1].OUTS_EMAIL', row: 5, col: 1 }
];

const suppInfoColumns = [
	{ Header: 'Study Area 1', accessor: 'app_interest.STUDY_AREA1', row: 0, col: 0 },
	{ Header: 'Study Area 2', accessor: 'app_interest.STUDY_AREA2', row: 1, col: 0 },
	{ Header: 'Study Area 3', accessor: 'app_interest.STUDY_AREA3', row: 2, col: 0 },
	{ Header: 'Comments', accessor: 'students.QE_COMMENTS', type: 'textarea', row: 3, col: 0 }
];

const thesisColumns = [
	{ Header: 'Registration Status', accessor: 'students.REG_STATUS', type: 'select', row: 0, col: 0,
		options: { value: 'REG_STATUS_NAME', label: 'REG_STATUS_NAME', entity: 'ADMISSIONS.REG_STATUS' }},
	{ Header: 'Degree Program', accessor: 'students.DEGREE', type: 'select', row: 1, col: 0,
		options: [{ value: null, label: 'Select...' },{ value: 'PhD', label: 'PhD' },{ value: 'MS', label: 'MS' }]},
	{ Header: 'Degree Date', accessor: 'students.THESIS_DATE_MM_YY', row: 2, col: 0 },
	{ Header: 'Candidacy Date', accessor: 'students.CANDIDACY', type: 'date', row: 3, col: 0 },
	{ Header: 'Chair', valueAccessor: 'students.THESIS_CHAIR_VALUE', accessor: 'students.THESIS_CHAIR', type: 'dropdown', row: 4, col: 0,
		options: { value: 'RES_DIRECTOR_NAME', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'RES_DIRECTOR_NAME', Operator: '=', StringAccessor: 'students.THESIS_CHAIR' }
	]}}},
	{ Header: 'Inside Reader', valueAccessor: 'students.INSIDE_READER_VALUE', accessor: 'students.INSIDE_READER', type: 'dropdown', row: 5, col: 0,
		options: { value: 'RES_DIRECTOR_NAME', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'RES_DIRECTOR_NAME', Operator: '=', StringAccessor: 'students.INSIDE_READER' }
	]}}},
	{ Header: 'Additional Reader', valueAccessor: 'students.ADD_READER_VALUE', accessor: 'students.ADD_READER', type: 'dropdown', row: 6, col: 0,
		options: { value: 'RES_DIRECTOR_NAME', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'RES_DIRECTOR_NAME', Operator: '=', StringAccessor: 'students.ADD_READER' }
	]}}},
	{ Header: 'Additional Reader Status', accessor: 'students.ADD_READER_STATUS', type: 'select', row: 7, col: 0,
		options: [{ value: '', label: 'Select...' },{ value: 'C', label: 'Co-Chair' },{ value: 'I', label: 'Inside Reader' }]},
	{ Header: 'Academic Senate Representative (ASR)', valueAccessor: 'students.OUTSIDE_READER_VALUE', accessor: 'students.OUTSIDE_READER', type: 'dropdown', row: 8, col: 0,
		options: { value: 'RES_DIRECTOR_NAME', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'RES_DIRECTOR_NAME', Operator: '=', StringAccessor: 'students.OUTSIDE_READER' }
	]}}},
	{ Header: 'ASR Department', accessor: 'students.OUTS_DEPT', row: 9, col: 0 },
	{ Header: 'Comments', accessor: 'students.THESIS_COMMENTS', type: 'textarea', row: 10, col: 0 }
];

const fnTableColumns = [
	{ Header: 'Note', accessor: 'NOTE_ENTRY', width: 800 },
	{ Header: 'Entered By', accessor: 'ENTERED_BY', width: 200 },
	{ Header: 'Entered Date', accessor: 'ENTRY_DATE', width: 150,
		Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) }
];

const fnEditColumns = [
	{ Header: 'Note', accessor: 'NOTE_ENTRY', type: 'textarea', labelColWidth: 3, valueColWidth: 9 }
];

const fnConvertColumns = [
	{ Header: 'Entered Date', accessor: 'ENTRY_DATE', type: 'date' }
];

export default withStudentDefaultRoute(Student);
